import { PublicKey } from '@solana/web3.js';
import { getTwoPermutations } from '../../utils/getTwoPermutations';
import { AccountInfoMap, Amm, Quote, QuoteParams, SwapParams } from '../amm';
import { RaydiumAmm } from '../raydium/raydiumAmm';
import { createRiskCheckAndFeeInstruction, createSetTokenLedgerInstruction } from '../jupiterInstruction';
import { SerumAmm } from '../serum/serumAmm';
import { SerumMarket } from '../market';

interface SplitSolution {
  outAmount: number;
  portion: number;
  firstQuote: Quote | undefined;
  secondQuote: Quote | undefined;
}

function isSplitSupported(firstAmm: Amm, secondAmm: Amm) {
  if (
    (firstAmm instanceof SerumAmm && secondAmm instanceof RaydiumAmm) ||
    (firstAmm instanceof RaydiumAmm && secondAmm instanceof SerumAmm) ||
    (firstAmm instanceof SerumAmm && secondAmm instanceof SerumAmm)
  ) {
    return false;
  }
  return true;
}

function requiresSplitSetup(firstAmm: Amm, secondAmm: Amm): boolean {
  if (firstAmm instanceof RaydiumAmm || secondAmm instanceof RaydiumAmm) {
    return true;
  } else if (firstAmm instanceof SerumAmm && secondAmm instanceof SerumAmm) {
    return true;
  }
  return false;
}

// Create an iteration to quote with a stepped split
export class SplitTradeAmm implements Amm {
  shouldSplitSetup: boolean;
  market: SerumMarket | null;
  private portion1: number = 0;
  private portion2: number = 0;

  constructor(private firstAmm: Amm, private secondAmm: Amm, public reserveTokenMints: PublicKey[]) {
    this.shouldSplitSetup = requiresSplitSetup(firstAmm, secondAmm);
    this.market =
      firstAmm instanceof SerumAmm ? firstAmm.market : secondAmm instanceof SerumAmm ? secondAmm.market : null;
  }

  static create(firstAmm: Amm, secondAmm: Amm) {
    if (!isSplitSupported(firstAmm, secondAmm)) return;

    const firstAmmTwoPermutations = getTwoPermutations(firstAmm.reserveTokenMints);
    const secondAmmTwoPermutations = getTwoPermutations(secondAmm.reserveTokenMints);

    for (const firstAmmTwoPermutation of firstAmmTwoPermutations) {
      for (const secondAmmTwoPermutation of secondAmmTwoPermutations) {
        if (firstAmmTwoPermutation.every((value, index) => value.equals(secondAmmTwoPermutation[index]))) {
          return new SplitTradeAmm(firstAmm, secondAmm, firstAmmTwoPermutation);
        }
      }
    }
  }

  get id() {
    return `${this.firstAmm.id}-${this.secondAmm.id}`;
  }

  get label() {
    const labelWithPortions = [
      { label: this.firstAmm.label, portion: this.portion1 },
      { label: this.secondAmm.label, portion: this.portion2 },
    ].sort((a, b) => b.portion - a.portion);

    return labelWithPortions.map(({ label, portion }) => `${label} (${portion}%)`).join(' + ');
  }

  getAccountsForUpdate() {
    return [];
  }

  update(_accountInfoMap: AccountInfoMap) {
    // Underlying amms are updated
  }

  getQuote(quoteParams: QuoteParams): Quote {
    const sourceMintString = quoteParams.sourceMint.toBase58();
    const amount = quoteParams.amount;
    // Portion in % directly to please the UI
    let bestSolution: SplitSolution = {
      outAmount: 0,
      portion: 0,
      firstQuote: undefined,
      secondQuote: undefined,
    };

    // Increase portion until 100
    for (let p = 100; (p -= 5); p > 0) {
      const firstAmount = Math.floor((amount * p) / 100);
      const secondAmount = amount - firstAmount;

      const firstQuote = this.firstAmm.getQuote({
        ...quoteParams,
        amount: firstAmount,
      });
      const secondQuote = this.secondAmm.getQuote({
        ...quoteParams,
        amount: secondAmount,
      });
      const outAmount = firstQuote.outAmount + secondQuote.outAmount;

      if (outAmount < bestSolution.outAmount) {
        break;
      }

      bestSolution = {
        outAmount,
        portion: p,
        firstQuote,
        secondQuote,
      };
    }

    if (!bestSolution.firstQuote || !bestSolution.secondQuote) {
      throw new Error('Unreachable: There was no better solution than getting 0 outAmount');
    }

    const { outAmount, portion, firstQuote, secondQuote } = bestSolution;
    const portion1 = portion;
    const portion2 = 100 - portion1;

    // For UI display
    this.portion1 = portion1;
    this.portion2 = portion2;

    let firstAmmFee = {
      amount: firstQuote.feeAmount,
      mint: firstQuote.feeMint,
    };
    let secondAmmFee = {
      amount: secondQuote.feeAmount,
      mint: secondQuote.feeMint,
    };

    if (firstAmmFee.mint !== secondAmmFee.mint) {
      // Then we convert destinationMint fee into a sourceMint, to please the current data structure
      // This will lead to inexact fees but this doesn't affect the user minimum out amount
      if (firstAmmFee.mint !== sourceMintString) {
        firstAmmFee = {
          amount: Math.floor((firstAmmFee.amount * amount * portion1) / 100 / bestSolution.outAmount),
          mint: sourceMintString,
        };
      }
      if (secondAmmFee.mint !== sourceMintString) {
        secondAmmFee = {
          amount: Math.floor((secondAmmFee.amount * amount * portion2) / 100 / bestSolution.outAmount),
          mint: sourceMintString,
        };
      }
    }

    const feePct = (portion1 * firstQuote.feePct + portion2 * secondQuote.feePct) / 100;
    const priceImpactPct = (portion1 * firstQuote.priceImpactPct + portion2 * secondQuote.priceImpactPct) / 100;

    // Not sure about the relevance on minInAmount and minOutAmount in this case
    const minInAmount =
      firstQuote.minInAmount || secondQuote.minInAmount
        ? (firstQuote.minInAmount ?? 0) + (secondQuote.minInAmount ?? 0)
        : undefined;
    const minOutAmount =
      firstQuote.minOutAmount || secondQuote.minOutAmount
        ? (firstQuote.minOutAmount ?? 0) + (secondQuote.minOutAmount ?? 0)
        : undefined;
    return {
      notEnoughLiquidity: false,
      inAmount: quoteParams.amount,
      outAmount: outAmount,
      minInAmount,
      minOutAmount,
      feeAmount: firstAmmFee.amount + secondAmmFee.amount,
      feeMint: firstAmmFee.mint, // Guaranteed identical mint at this point
      feePct,
      priceImpactPct,
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    const amount = swapParams.amount!; // Cannot be null!

    // We rely on the fact that this.portion1 is set, what if it isn't?
    const firstAmount = Math.floor((amount * this.portion1) / 100);
    const secondAmount = amount - firstAmount;

    return [
      createSetTokenLedgerInstruction(swapParams.destinationTokenAccount),
      ...this.firstAmm.createSwapInstructions({
        ...swapParams,
        amount: firstAmount,
        minimumOutAmount: 0,
        platformFee: undefined,
      }),
      ...this.secondAmm.createSwapInstructions({
        ...swapParams,
        amount: secondAmount,
        minimumOutAmount: 0,
        platformFee: undefined,
      }),
      createRiskCheckAndFeeInstruction(
        swapParams.destinationTokenAccount,
        swapParams.userTransferAuthority,
        swapParams.minimumOutAmount,
        swapParams.platformFee,
      ),
    ];
  }
}
