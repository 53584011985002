import { StableSwap } from '@saberhq/stableswap-sdk';
import { PublicKey } from '@solana/web3.js';
import { Fraction, ONE, Stable, TWO } from '@jup-ag/math';
import JSBI from 'jsbi';
import Decimal from 'decimal.js';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { createSaberExchangeInstruction } from '../jupiterInstruction';
import { deserializeAccount } from '../../utils/deserializeAccount';
import { AccountInfo } from '@solana/spl-token';

export class SaberAmm implements Amm {
  label = 'Saber' as const;
  private tokenAccounts: AccountInfo[] = [];
  private calculator: Stable;

  constructor(private stableSwap: StableSwap) {
    this.calculator = new Stable(
      TWO,
      JSBI.BigInt(this.stableSwap.state.targetAmpFactor.toString()),
      [ONE, ONE], // TODO: How to get the token decimal?
      new Fraction(this.stableSwap.state.fees.trade.numerator, this.stableSwap.state.fees.trade.denominator),
    );
  }

  get id() {
    return this.stableSwap.config.swapAccount.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.stableSwap.state.tokenA.reserve, this.stableSwap.state.tokenB.reserve];
  }

  update(accountInfoMap: AccountInfoMap) {
    let tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) {
        throw new Error('Invalid token account data');
      }
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, destinationMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    const feePct = new Decimal(this.stableSwap.state.fees.trade.asFraction.toFixed(4));

    const [inputIndex, outputIndex] = this.tokenAccounts[0].mint.equals(sourceMint) ? [0, 1] : [1, 0];

    const result = this.calculator.exchange(
      tokenAccountsToJSBIs(this.tokenAccounts),
      JSBI.BigInt(amount),
      inputIndex,
      outputIndex,
    );

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.toNumber(result.expectedOutputAmount),
      feeAmount: JSBI.toNumber(result.fees),
      feeMint: destinationMint.toBase58(),
      feePct: feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams) {
    // The input or output might require the magic wrapping
    return [
      createSaberExchangeInstruction(
        this.stableSwap,
        sourceMint,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        amount,
        minimumOutAmount,
        platformFee,
      ),
    ];
  }

  get reserveTokenMints() {
    return [this.stableSwap.state.tokenA.mint, this.stableSwap.state.tokenB.mint];
  }
}
