import { struct, u8, Layout, blob } from '@solana/buffer-layout';
import { PublicKey, AccountInfo } from '@solana/web3.js';
import { u64 } from '@solana/spl-token';
import { publicKey, uint64 } from '../../utils/layout';

interface SenchaSwapLayout {
  discriminator: any;
  factory: PublicKey;
  bump: number;
  index: u64;
  admin: PublicKey;
  token0Reserves: PublicKey;
  token0Mint: PublicKey;
  token0Fees: PublicKey;
  token1Reserves: PublicKey;
  token1Mint: PublicKey;
  token1Fees: PublicKey;
  isPaused: number;
  poolMint: PublicKey;
  tradeFeeKbps: u64;
  withdrawFeeKbps: u64;
  adminTradeFeeKbps: u64;
  adminWithdrawFeeKbps: u64;
}

export const SenchaSwapLayout = struct<SenchaSwapLayout>([
  blob(8, 'discriminator'),
  publicKey('factory'),
  u8('bump'),
  uint64('index'),
  publicKey('admin'),
  publicKey('token0Reserves'),
  publicKey('token0Mint'),
  publicKey('token0Fees'),
  publicKey('token1Reserves'),
  publicKey('token1Mint'),
  publicKey('token1Fees'),
  u8('isPaused'),
  publicKey('poolMint'),
  uint64('tradeFeeKbps'),
  uint64('withdrawFeeKbps'),
  uint64('adminTradeFeeKbps'),
  uint64('adminWithdrawFeeKbps'),
]);

export interface SenchaPoolState {
  programId: PublicKey;
  isPaused: boolean;
  bump: number;
  ammId: PublicKey;
  token0Reserves: PublicKey;
  token1Reserves: PublicKey;
  token0Mint: PublicKey;
  token1Mint: PublicKey;
  token0Fees: PublicKey;
  token1Fees: PublicKey;
  poolMint: PublicKey;
  tradeFeeKbps: number;
}

export const accountInfoToSenchaPoolState = (address: PublicKey, accountInfo: AccountInfo<Buffer>): SenchaPoolState => {
  const programId = accountInfo.owner;
  const decoded = SenchaSwapLayout.decode(accountInfo.data);

  return {
    programId,
    isPaused: Boolean(decoded.isPaused),
    bump: decoded.bump,
    ammId: address,
    token0Reserves: decoded.token0Reserves,
    token1Reserves: decoded.token1Reserves,
    token0Mint: decoded.token0Mint,
    token1Mint: decoded.token1Mint,
    token0Fees: decoded.token0Fees,
    token1Fees: decoded.token1Fees,
    poolMint: decoded.poolMint,
    tradeFeeKbps: decoded.tradeFeeKbps.toNumber(),
  };
};
