import { Fraction, Stable } from '@jup-ag/math';
import { AccountInfo, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { createMercurialExchangeInstruction } from '../jupiterInstruction';
import { deserializeAccount } from '../../utils/deserializeAccount';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import { accountInfoToMercurialSwapLayout, FEE_DENOMINATOR, MercurialSwapLayoutState } from './swapLayout';

interface MercurialParams {
  tokenMints: string[];
}

export class MercurialAmm implements Amm {
  label = 'Mercurial';
  swapLayout: MercurialSwapLayoutState;
  private tokenAccounts: TokenAccountInfo[] = [];
  private calculator: Stable;

  static decodeSwapLayout = accountInfoToMercurialSwapLayout;

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>, private params: MercurialParams) {
    this.swapLayout = accountInfoToMercurialSwapLayout(address, accountInfo);
    this.calculator = new Stable(
      JSBI.BigInt(this.swapLayout.tokenAccountsLength),
      JSBI.BigInt(this.swapLayout.amplificationCoefficient),
      this.swapLayout.precisionMultipliers.map((precisionMultiplier) => JSBI.BigInt(precisionMultiplier)),
      new Fraction(JSBI.BigInt(this.swapLayout.feeNumerator), JSBI.BigInt(FEE_DENOMINATOR)),
    );
  }

  get id() {
    return this.swapLayout.ammId.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return this.swapLayout.tokenAccounts;
  }

  update(accountInfoMap: AccountInfoMap) {
    let tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos
      .map((info) => deserializeAccount(info.data))
      .filter((x): x is TokenAccountInfo => x !== null);
  }

  getQuote({ sourceMint, destinationMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    const inputIndex = this.tokenAccounts.findIndex((tokenAccount) => tokenAccount.mint.equals(sourceMint));
    const outputIndex = this.tokenAccounts.findIndex((tokenAccount) => tokenAccount.mint.equals(destinationMint));
    const result = this.calculator.exchange(
      tokenAccountsToJSBIs(this.tokenAccounts),
      JSBI.BigInt(amount),
      inputIndex,
      outputIndex,
    );

    const feePct = this.swapLayout.feeNumerator / FEE_DENOMINATOR;

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.toNumber(result.expectedOutputAmount),
      feeAmount: JSBI.toNumber(result.fees),
      feeMint: destinationMint.toBase58(),
      feePct: feePct,
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions({
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams): TransactionInstruction[] {
    return [
      createMercurialExchangeInstruction(
        this.swapLayout,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        amount,
        minimumOutAmount,
        platformFee,
      ),
    ];
  }

  get reserveTokenMints() {
    return this.params.tokenMints.map((tokenMint) => new PublicKey(tokenMint));
  }
}
