import { TransactionError } from '@mercurial-finance/optimist';
import {
  ConfirmedTransactionMeta,
  Connection,
  PublicKey,
  Transaction,
  TransactionResponse,
  TransactionSignature,
} from '@solana/web3.js';
import promiseRetry from 'promise-retry';
import { WRAPPED_SOL_MINT } from '../constants';

function diffTokenBalance(accountKeyIndex: number, meta: ConfirmedTransactionMeta): number | undefined {
  const postBalance = meta.postTokenBalances?.find(
    (postTokenBalance) => postTokenBalance.accountIndex === accountKeyIndex,
  )?.uiTokenAmount.amount;
  const preBalance = meta.preTokenBalances?.find((preTokenBalance) => preTokenBalance.accountIndex === accountKeyIndex)
    ?.uiTokenAmount.amount;

  // When token account is created it isn't present in preBalance
  if (!postBalance) return;
  return Math.abs(parseInt(postBalance) - (preBalance !== undefined ? parseInt(preBalance) : 0));
}

export function extractTokenBalanceChangeFromTransaction(
  transactionResult: TransactionResponse,
  tokenAccountAddress: PublicKey,
): number | undefined {
  const message = transactionResult.transaction.message;
  const meta = transactionResult.meta;
  if (!meta) {
    return;
  }
  const index = message.accountKeys.findIndex((p) => p.equals(tokenAccountAddress));

  return diffTokenBalance(index, meta);
}

export function extractWrappedSOLChangeFromTransaction(transactionResult: TransactionResponse): number | undefined {
  const meta = transactionResult.meta;
  if (!meta) {
    return;
  }
  const index = meta.preTokenBalances?.find(
    (preTokenBalance) => preTokenBalance.mint === WRAPPED_SOL_MINT.toString(),
  )?.accountIndex;

  if (!index) return;

  return diffTokenBalance(index, meta);
}

export function extractSOLChangeFromTransaction(transactionResult: TransactionResponse): number | undefined {
  const meta = transactionResult.meta;
  if (!meta) {
    return;
  }
  const index = meta.postTokenBalances?.find(
    (postTokenBalance) => postTokenBalance.mint === WRAPPED_SOL_MINT.toString(),
  )?.accountIndex;

  if (!index) return;

  return diffTokenBalance(index, meta);
}

export function getWritableKeys(transaction: Transaction) {
  return [
    ...new Set(
      transaction.instructions
        .map((inst) => inst.keys.filter((key) => key.isWritable).map((k) => k.pubkey))
        .reduce((acc, el) => acc.concat(el)),
    ).values(),
  ];
}

export function getTokenBalanceChangesFromTransactionResponse(
  inputMint: PublicKey,
  outputMint: PublicKey,
  sourceAddress: PublicKey,
  destinationAddress: PublicKey,
  transactionResponse: TransactionResponse | null,
) {
  let sourceTokenBalanceChange: number | undefined;
  let destinationTokenBalanceChange: number | undefined;

  if (transactionResponse) {
    sourceTokenBalanceChange =
      inputMint.toBase58() === WRAPPED_SOL_MINT.toString()
        ? extractWrappedSOLChangeFromTransaction(transactionResponse)
        : extractTokenBalanceChangeFromTransaction(transactionResponse, sourceAddress);
    destinationTokenBalanceChange =
      outputMint.toBase58() === WRAPPED_SOL_MINT.toString()
        ? extractSOLChangeFromTransaction(transactionResponse)
        : extractTokenBalanceChangeFromTransaction(transactionResponse, destinationAddress);
  }

  if (!(sourceTokenBalanceChange && destinationTokenBalanceChange)) {
    throw new Error('Cannot find source or destination token account balance change');
  }

  return [sourceTokenBalanceChange, destinationTokenBalanceChange];
}

export async function pollForConfirmedTransaction(
  connection: Connection,
  txid: TransactionSignature,
): Promise<TransactionResponse | null> {
  return promiseRetry(
    async (retry) => {
      const response = await connection.getTransaction(txid, {
        commitment: 'confirmed',
      });
      if (!response) {
        retry(new TransactionError('Transaction was not confirmed', txid));
      }
      return response;
    },
    {
      retries: 30,
      minTimeout: 500,
    },
  ).catch(() => null);
}
