import JSBI from 'jsbi';
import Decimal from 'decimal.js';

export type SwapResult = {
  priceImpact: Decimal;
  fees: JSBI;
  expectedOutputAmount: JSBI;
};

export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const TWO = JSBI.BigInt(2);

export function abs(number: JSBI) {
  if (JSBI.GE(number, ZERO)) {
    return number;
  }

  return JSBI.unaryMinus(number);
}

export function sumArray(numbers: JSBI[]) {
  return numbers.reduce((prev, curr) => {
    return JSBI.add(prev, curr);
  }, ZERO);
}

export function mulArray(numbers: JSBI[]) {
  return numbers.reduce((prev, curr) => {
    return JSBI.multiply(prev, curr);
  }, ONE);
}

export function ceilingDivision(dividend: JSBI, divisor: JSBI): [JSBI, JSBI] {
  let quotient = JSBI.divide(dividend, divisor);
  if (JSBI.equal(quotient, ZERO)) {
    return [ZERO, divisor];
  }

  let remainder = JSBI.remainder(dividend, divisor);
  if (JSBI.greaterThan(remainder, ZERO)) {
    quotient = JSBI.add(quotient, ONE);
    divisor = JSBI.divide(dividend, quotient);
    remainder = JSBI.remainder(dividend, quotient);
    if (JSBI.greaterThan(remainder, ZERO)) {
      divisor = JSBI.add(divisor, ONE);
    }
  }

  return [quotient, divisor];
}

export function toDecimal(input: JSBI, shift = 0) {
  return new Decimal(input.toString()).div(new Decimal(10).pow(shift));
}
