import { PublicKey } from '@solana/web3.js';

// TODO: Remove harcoded pools before merge
const TOKEN_SWAP_POOLS: PublicKey[] = [];

export const STEP_TOKEN_SWAP_PROGRAM_ID = new PublicKey('SSwpMgqNDsyV7mAgN9ady4bDVu5ySjmmXejXvy2vLt1');

export const PROGRAM_ID_TO_LABEL = new Map<string, string>([
  ['9W959DqEETiGZocYWCQPaJ6sBmUzgfxXfqGeTEdp3aQP', 'Orca'],
  [STEP_TOKEN_SWAP_PROGRAM_ID.toBase58(), 'Step'],
  ['PSwapMdSai8tjrEXcxFeQth87xC4rRsa4VA5mhGhXkP', 'Penguin'],
]);

export function getTokenSwapPools(cluster: string) {
  return cluster === 'mainnet-beta' ? TOKEN_SWAP_POOLS : [];
}
