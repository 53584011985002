import JSBI from 'jsbi';
import { Curve } from '../math/curve';
import { ONE, SwapResult, toDecimal, ZERO, TWO } from '../utils';
import Decimal from 'decimal.js';
import { Fraction } from '../fraction';
import { calculateFeeAmount } from './fees';

export class TokenSwapStable {
  private curve: Curve;

  constructor(amp: JSBI, private traderFee: Fraction, private ownerFee: Fraction) {
    this.curve = new Curve(TWO, amp, [ONE, ONE]);
  }

  public exchange(tokenAmounts: JSBI[], inputTradeAmount: JSBI, outputIndex: number): SwapResult {
    let inputIndex = outputIndex === 0 ? 1 : 0;
    let expectedOutputAmount = this.getExpectedOutputAmount(tokenAmounts, inputTradeAmount, inputIndex, outputIndex);

    return {
      priceImpact: this.getPriceImpact(tokenAmounts, inputTradeAmount, expectedOutputAmount, inputIndex, outputIndex),
      fees: this.getFees(inputTradeAmount),
      expectedOutputAmount,
    };
  }

  private getPriceImpact(
    tokenAmounts: JSBI[],
    inputTradeAmountJSBI: JSBI,
    expectedOutputAmountJSBI: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): Decimal {
    if (
      JSBI.equal(inputTradeAmountJSBI, ZERO) ||
      JSBI.equal(tokenAmounts[inputIndex], ZERO) ||
      JSBI.equal(tokenAmounts[outputIndex], ZERO)
    ) {
      return new Decimal(0);
    }

    const noSlippageOutputAmount = toDecimal(
      this.getExpectedOutputAmountWithNoSlippage(tokenAmounts, inputTradeAmountJSBI, inputIndex, outputIndex),
    );

    const expectedOutputAmount = toDecimal(expectedOutputAmountJSBI);
    const impact = noSlippageOutputAmount.sub(expectedOutputAmount).div(noSlippageOutputAmount);

    return impact;
  }

  private getFees(inputTradeAmount: JSBI): JSBI {
    const tradingFee = calculateFeeAmount(inputTradeAmount, this.traderFee);
    const ownerFee = calculateFeeAmount(inputTradeAmount, this.ownerFee);

    return JSBI.add(tradingFee, ownerFee);
  }

  private getExpectedOutputAmount(
    tokenAmounts: JSBI[],
    inputTradeAmount: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): JSBI {
    const inputTradeAmountLessFees = this.getInputAmountLessFees(inputTradeAmount);

    return this.getOutputAmount(tokenAmounts, inputTradeAmountLessFees, inputIndex, outputIndex);
  }

  private getExpectedOutputAmountWithNoSlippage(
    tokenAmounts: JSBI[],
    inputTradeAmount: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): JSBI {
    const inputTradeAmountLessFees = this.getInputAmountLessFees(inputTradeAmount);

    return this.getOutputAmountWithNoSlippage(tokenAmounts, inputTradeAmountLessFees, inputIndex, outputIndex);
  }

  private getInputAmountLessFees(inputTradeAmount: JSBI): JSBI {
    return JSBI.subtract(inputTradeAmount, this.getFees(inputTradeAmount));
  }

  private getOutputAmount(
    tokenAmounts: JSBI[],
    inputTradeAmountLessFees: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): JSBI {
    return this.curve.exchange(tokenAmounts, inputIndex, outputIndex, inputTradeAmountLessFees, false);
  }

  private getOutputAmountWithNoSlippage(
    tokenAmounts: JSBI[],
    inputTradeAmountLessFees: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): JSBI {
    return this.curve.computeBaseY(tokenAmounts, inputIndex, outputIndex, inputTradeAmountLessFees);
  }
}
