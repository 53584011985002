import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  Cluster,
  Connection,
  FeeCalculator,
  Keypair,
  PublicKey,
  Transaction,
  TransactionResponse,
  TransactionSignature,
} from '@solana/web3.js';

import { OpenOrders } from '@project-serum/serum';
import {
  computeRouteMap,
  computeRoutes,
  getAllAmms,
  getTokenRouteSegments,
  isSplitSetupRequired,
  RouteInfo,
} from './routes';
import { MarketInfo } from './market';
import { DEVNET_SERUM_DEX_PROGRAM, JUPITER_WALLET, MAINNET_SERUM_DEX_PROGRAM, WRAPPED_SOL_MINT } from '../constants';
import { getDepositAndFeeFromInstructions, NO_PLATFORM_FEE } from './fee';
import { deleteInstructionCache, getCacheMintKey, getInstructionCache } from './cache';
import routeToInstructions, { routeAtaInstructions } from './routeToInstructions';
import { getOrCreateOpenOrdersAddress } from './serum/openOrders';
import { createAndCloseWSOLAccount } from '../utils/token';
import { getEmptyInstruction } from '../utils/instruction';
import { TransactionBuilder } from '../utils/TransactionBuilder';
import { Owner } from '../utils/Owner';
import {
  pollForConfirmedTransaction,
  getTokenBalanceChangesFromTransactionResponse,
} from '../utils/transactionHelpers';
import {
  createCreateTokenLedgerInstruction,
  createOpenOrdersInstruction,
  createRaydiumSwapInstruction,
  createMercurialExchangeInstruction,
  createSerumSwapInstruction,
  createSetTokenLedgerInstruction,
} from './jupiterInstruction';
import { fetchRoutes, computeRouteInfos } from './computeRouteInfos';
import type { SignerWalletAdapter } from '@solana/wallet-adapter-base';
import { InstructionCache, TokenRouteSegments, PlatformFeeAndAccounts, QuoteMintToReferrer } from './types';
import { SerumAmm } from './serum/serumAmm';
import { SaberAmm } from './saber/saberAmm';
import { SplTokenSwapAmm } from './spl-token-swap/splTokenSwapAmm';
import { MercurialAmm } from './mercurial/mercurialAmm';
import { AldrinAmm } from './aldrin/aldrinAmm';
import { RaydiumAmm } from './raydium/raydiumAmm';
import { CropperAmm } from './cropper/cropperAmm';
import { SenchaAmm } from './sencha/senchaAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { TokenMintAddress } from './types';
import { getPlatformFeeAccounts } from './fee';
import { Amm } from './amm';
import { validateTransactionResponse } from '../utils/tx/errors';
import { TransactionError } from '@mercurial-finance/optimist';
import { getSaberWrappedDecimalsAmms, SaberAddDecimalsAmm } from './saber/saberAddDecimalsAmm';

export type SerumOpenOrdersMap = Map<string, PublicKey>;
export { MarketInfo } from './market';
export { getPlatformFeeAccounts } from './fee';
export * from './types';
export { RouteInfo, TransactionFeeInfo, getRouteInfoUniqueId } from './routes';
export { getSaberWrappedDecimalsAmms };
export {
  AldrinAmm,
  RaydiumAmm,
  SerumAmm,
  SaberAmm,
  SplTokenSwapAmm,
  MercurialAmm,
  CropperAmm,
  SenchaAmm,
  SaberAddDecimalsAmm,
  SplitTradeAmm,
};

export type SwapResult =
  | {
      txid: string;
      inputAddress: PublicKey;
      outputAddress: PublicKey;
      inputAmount: number | undefined;
      outputAmount: number | undefined;
    }
  | {
      error?: TransactionError;
    };

type InputMintAndOutputMint = string;

type JupiterLoadParams = {
  connection: Connection;
  cluster: Cluster;
  user?: PublicKey | Keypair;
  platformFeeAndAccounts?: PlatformFeeAndAccounts;
  quoteMintToReferrer?: Map<TokenMintAddress, PublicKey>;
  /**
   * If === -1, mean it will not fetch when shouldFetch == false
   * If === 0, mean it will fetch everytime
   */
  routeCacheDuration?: number;
  wrapUnwrapSOL?: boolean;
  marketUrl?: string;
};

export type IConfirmationTxDescription = 'SETUP' | 'SWAP' | 'CLEANUP';
type ExecuteParams = {
  wallet?: Pick<SignerWalletAdapter, 'sendTransaction' | 'signAllTransactions' | 'signTransaction'>;
  /**
   * Allows to customize control of awaiting confirmation in the single/multi transaction flow
   */
  confirmationWaiterFactory?: (
    txid: TransactionSignature,
    totalTxs: number,
    txDescription: IConfirmationTxDescription,
  ) => Promise<TransactionResponse | null>;
};

export class Jupiter {
  /* promise because we can choose not to await it when we dont need it */
  private serumOpenOrdersPromise: Promise<SerumOpenOrdersMap> | undefined = undefined;
  private instructionCache: InstructionCache = new Map();
  private user: Keypair | PublicKey | undefined;
  private routeCache = new Map<InputMintAndOutputMint, { fetchTimestamp: number }>();

  constructor(
    private connection: Connection,
    private cluster: Cluster,
    public tokenRouteSegments: TokenRouteSegments,
    private feeCalculator: FeeCalculator,
    private platformFeeAndAccounts: PlatformFeeAndAccounts,
    /** Referrer account to collect Serum referrer fees for each given quote mint, the referrer fee is 20% of the Serum protocol fee */
    private quoteMintToReferrer: QuoteMintToReferrer,
    /** route cache duration in ms */
    private routeCacheDuration: number = 0,
    /** When set to true (default) native SOL is wrapped and wSOL unwrapped in each swap, otherwise it assumes wSOL is funded when it exists */
    private wrapUnwrapSOL: boolean = true,
  ) {}

  /**
   * load performs the necessary async scaffolding of the Jupiter object
   */
  static async load({
    connection,
    cluster,
    user,
    platformFeeAndAccounts = NO_PLATFORM_FEE,
    quoteMintToReferrer,
    routeCacheDuration = 0,
    wrapUnwrapSOL = true,
    // @internal,
    marketUrl,
  }: JupiterLoadParams) {
    const [
      tokenRouteSegments,
      {
        value: { feeCalculator },
      },
      defaultQuoteMintToReferrer,
    ] = await Promise.all([
      Jupiter.fetchTokenRouteSegments(connection, cluster, marketUrl),
      connection.getRecentBlockhashAndContext('processed'),
      getPlatformFeeAccounts(connection, new PublicKey(JUPITER_WALLET)),
    ]);

    const jupiter = new Jupiter(
      connection,
      cluster,
      tokenRouteSegments,
      feeCalculator,
      platformFeeAndAccounts,
      quoteMintToReferrer || defaultQuoteMintToReferrer,
      routeCacheDuration,
      wrapUnwrapSOL,
    );
    if (user) jupiter.setUserPublicKey(user);
    return jupiter;
  }

  getAccountToAmmMap() {
    const accountToAmmMap = new Map<string, Amm>();
    this.tokenRouteSegments.forEach((tokenRouteSegment) => {
      Array.from(tokenRouteSegment.values()).forEach((marketInfos) => {
        marketInfos.forEach(({ amm }) => {
          amm.getAccountsForUpdate().forEach((account) => {
            accountToAmmMap.set(account.toBase58(), amm);
          });
        });
      });
    });
    return accountToAmmMap;
  }

  async computeRoutes({
    inputMint,
    outputMint,
    inputAmount,
    slippage,
    feeBps = 0,
    forceFetch,
    intermediateTokens,
  }: {
    inputMint: PublicKey;
    outputMint: PublicKey;
    inputAmount: number;
    slippage: number;
    feeBps?: number;
    forceFetch?: boolean;
    intermediateTokens?: string[];
  }) {
    const getDepositAndFees = async (marketInfos: MarketInfo[]) => {
      if (this.user && this.serumOpenOrdersPromise) {
        const owner = new Owner(this.user);
        return getDepositAndFeeFromInstructions({
          connection: this.connection,
          feeCalculator: this.feeCalculator!,
          instructionCache: this.instructionCache,
          marketInfos: marketInfos,
          serumOpenOrdersPromise: this.serumOpenOrdersPromise,
          userPublicKey: owner.publicKey,
          unwrapSOL: this.wrapUnwrapSOL,
        });
      }
    };

    const inputMintString = inputMint.toBase58();
    const outputMintString = outputMint.toBase58();

    // Platform fee can only be applied when fee account exists
    const platformFeeBps =
      feeBps ||
      (this.platformFeeAndAccounts.feeAccounts.get(outputMintString) ? this.platformFeeAndAccounts.feeBps : 0);

    const now = new Date().getTime();

    // do sort so that it's always the same order for the same inputMint and outputMint and vice versa
    const inputMintAndOutputMint = [inputMintString, outputMintString].sort((a, b) => a.localeCompare(b)).join('');

    const routeCache = this.routeCache.get(inputMintAndOutputMint);

    const routes = computeRoutes(inputMintString, outputMintString, this.tokenRouteSegments, intermediateTokens);

    let shouldBustCache = false;
    // special -1 condition to not fetch
    if (this.routeCacheDuration === -1) {
      shouldBustCache = false;
    } else if (this.routeCacheDuration === 0) {
      shouldBustCache = true;
    } else {
      if (routeCache) {
        const { fetchTimestamp } = routeCache;
        if (now - fetchTimestamp > this.routeCacheDuration) {
          shouldBustCache = true;
        }
      } else {
        shouldBustCache = true;
      }
    }

    if (forceFetch || shouldBustCache) {
      await fetchRoutes(this.connection, routes);
      this.routeCache.set(inputMintAndOutputMint, {
        fetchTimestamp: new Date().getTime(),
      });
    }

    try {
      const routesInfos = computeRouteInfos({
        routes,
        amount: inputAmount,
        inputMint,
        outputMint,
        getDepositAndFeeForRoute: getDepositAndFees,
        slippage,
        platformFeeBps,
      });

      return {
        routesInfos,
        /* indicate if the result is fetched or get from cache */
        cached: !(forceFetch || shouldBustCache),
      };
    } catch (e) {
      throw e;
    } finally {
      // clear cache if it is expired
      this.routeCache.forEach(({ fetchTimestamp }, key) => {
        if (fetchTimestamp - now > this.routeCacheDuration) {
          this.routeCache.delete(key);
        }
      });
    }
  }

  setUserPublicKey(userPublicKey: Keypair | PublicKey) {
    this.user = userPublicKey;
    const owner = new Owner(this.user);
    this.serumOpenOrdersPromise = Jupiter.findSerumOpenOrdersForOwner({
      connection: this.connection,
      cluster: this.cluster,
      userPublicKey: owner.publicKey,
    });
  }

  /**
   * The token route segments contains all the routes and the market meta information.
   */
  static async fetchTokenRouteSegments(connection: Connection, cluster: Cluster, marketUrl?: string) {
    const amms = await getAllAmms(connection, cluster, marketUrl);

    const tokenRouteSegments = getTokenRouteSegments(amms);

    return tokenRouteSegments;
  }

  /**
   * This generate a routeMap which represents every possible output token mint for a given input token mint.
   * For example, we have SOL to USDC and this pairs have many routings like
   * SOL => USDT
   * USDT => USDC
   * SOL => USDC
   *
   * From here we know that we can have 2 different routing of SOL => USDC.
   * We do single level routing map but for all coins which result in the route map below:
   * SOL => USDT, USDC
   * USDT => SOL
   * USDC => SOL, USDT
   *
   * From this route map we can map out all possible route from one to another by checking the intersection.
   */
  getRouteMap() {
    return computeRouteMap(this.tokenRouteSegments);
  }

  /**
   * Query existing open order account, this query is slow.
   * We suggest to fetch this in the background.
   */
  static findSerumOpenOrdersForOwner = async ({
    userPublicKey,
    cluster,
    connection,
  }: {
    userPublicKey: PublicKey;
    cluster: Cluster;
    connection: Connection;
  }) => {
    const newMarketToOpenOrdersAddress: SerumOpenOrdersMap = new Map();

    if (userPublicKey) {
      const programId = cluster === 'mainnet-beta' ? MAINNET_SERUM_DEX_PROGRAM : DEVNET_SERUM_DEX_PROGRAM;

      const allOpenOrders = await OpenOrders.findForOwner(connection, userPublicKey, programId);

      allOpenOrders.forEach((openOrders) => {
        newMarketToOpenOrdersAddress.set(openOrders.market.toString(), openOrders.address);
      });
    }
    return newMarketToOpenOrdersAddress;
  };

  public exchange: (params: {
    route: RouteInfo;
    userPublicKey?: PublicKey;
    feeAccount?: PublicKey;
    wrapUnwrapSOL?: boolean;
  }) => Promise<{
    transactions: {
      setupTransaction?: Transaction;
      swapTransaction: Transaction;
      cleanupTransaction?: Transaction;
    };
    execute: (params?: ExecuteParams) => Promise<SwapResult>;
  }> = async ({ route, userPublicKey, feeAccount, wrapUnwrapSOL }) => {
    const { connection, serumOpenOrdersPromise } = this;
    const user: PublicKey | Keypair | undefined = userPublicKey || this.user;
    if (!user) {
      throw new Error('user not found');
    }

    const owner = new Owner(user);

    const lastMarketInfoIndex = route.marketInfos.length - 1;
    const inputMint = route.marketInfos[0].inputMint;
    const outputMint = route.marketInfos[lastMarketInfoIndex].outputMint;
    const _wrapUnwrapSOL = wrapUnwrapSOL ?? this.wrapUnwrapSOL;
    const cacheKey = getCacheMintKey(route.marketInfos);

    let instructions = getInstructionCache({
      instructionCache: this.instructionCache,
      walletPublicKey: owner.publicKey.toBase58(),
      cacheKey,
    });

    if (!instructions) {
      const [ataInstructions, openOrdersInstructions] = await Promise.all([
        routeAtaInstructions(connection, route.marketInfos, owner.publicKey, _wrapUnwrapSOL),
        Promise.all(
          route.marketInfos.map(async ({ marketMeta: { amm } }) => {
            if (amm instanceof SerumAmm || amm instanceof SplitTradeAmm) {
              if (!amm.market) return;
              return await getOrCreateOpenOrdersAddress(
                connection,
                owner.publicKey,
                amm.market,
                await serumOpenOrdersPromise,
              );
            }
            return;
          }),
        ),
      ]);

      instructions = {
        intermediate: ataInstructions.userIntermediaryTokenAccountResult,
        destination: ataInstructions.userDestinationTokenAccountResult,
        openOrders: openOrdersInstructions,
      };
    }

    const sourceInstruction =
      inputMint.equals(WRAPPED_SOL_MINT) && _wrapUnwrapSOL
        ? await createAndCloseWSOLAccount(connection, owner.publicKey, route.inAmount)
        : {
            ...getEmptyInstruction(),
            address: await Token.getAssociatedTokenAddress(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              inputMint,
              owner.publicKey,
            ),
          };

    // Construct platform fee
    feeAccount = feeAccount || this.platformFeeAndAccounts.feeAccounts.get(outputMint.toBase58());

    const platformFee = feeAccount
      ? {
          feeBps:
            this.platformFeeAndAccounts.feeBps ||
            Math.floor(route.marketInfos[lastMarketInfoIndex].platformFee.pct * 100),
          feeAccount,
        }
      : undefined;

    const preparedInstructions = await routeToInstructions(
      owner,
      instructions.openOrders.map((oo) => oo?.address),
      sourceInstruction.address,
      instructions.intermediate?.address,
      instructions.destination.address,
      route,
      platformFee,
      this.quoteMintToReferrer,
    );

    const splitSetupRequired = isSplitSetupRequired(route.marketInfos);

    const setupTransactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    const transactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    const cleanupTransactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    if (splitSetupRequired) {
      if (instructions.openOrders) {
        instructions.openOrders.forEach((openOrders) => {
          if (openOrders) {
            setupTransactionBuilder.addInstruction(openOrders);
          }
        });
      }

      if (instructions.intermediate) {
        setupTransactionBuilder.addInstruction({
          ...instructions.intermediate,
          cleanupInstructions: [],
        });
      }

      setupTransactionBuilder.addInstruction({
        ...sourceInstruction,
        cleanupInstructions: [],
      });

      cleanupTransactionBuilder
        .addInstruction({
          ...getEmptyInstruction(),
          cleanupInstructions: sourceInstruction.cleanupInstructions,
        })
        .addInstruction({
          ...getEmptyInstruction(),
          cleanupInstructions: instructions.intermediate?.cleanupInstructions ?? [],
        });

      // if source address the same as destination address, then we don't need to setup or cleanup twice, mainly SOL-SOL
      if (!sourceInstruction.address.equals(instructions.destination.address)) {
        setupTransactionBuilder.addInstruction({
          ...instructions.destination,
          cleanupInstructions: [],
        });

        cleanupTransactionBuilder.addInstruction({
          ...getEmptyInstruction(),
          cleanupInstructions: instructions.destination.cleanupInstructions,
        });
      }
    } else {
      if (instructions.openOrders) {
        instructions.openOrders.forEach((openOrders) => {
          if (openOrders) {
            transactionBuilder.addInstruction(openOrders);
          }
        });
      }

      if (instructions.intermediate) {
        transactionBuilder.addInstruction(instructions.intermediate);
      }

      transactionBuilder.addInstruction(sourceInstruction);

      // if source address the same as destination address, then we don't need to setup or cleanup twice, mainly SOL-SOL
      if (!sourceInstruction.address.equals(instructions.destination.address)) {
        transactionBuilder.addInstruction(instructions.destination);
      }
    }

    transactionBuilder.addInstruction(preparedInstructions);

    const recentBlockHash = (await this.connection.getRecentBlockhash('finalized')).blockhash;

    const { transaction: setupTransaction } = await setupTransactionBuilder.build(recentBlockHash);

    const { transaction } = await transactionBuilder.build(recentBlockHash);

    const { transaction: cleanupTransaction } = await cleanupTransactionBuilder.build(recentBlockHash);

    // Is this horrible? Yes.
    const [setupTransactionObject, swapTransactionObject, cleanupTransactionObject] = ((): [
      one: Transaction | undefined,
      two: Transaction,
      three: Transaction | undefined,
    ] => {
      if (setupTransaction.instructions.length && cleanupTransaction.instructions.length) {
        return [setupTransaction, transaction, cleanupTransaction] as [
          one: Transaction | undefined,
          two: Transaction,
          three: Transaction | undefined,
        ];
      } else if (setupTransaction.instructions.length) {
        const [first, second] = [setupTransaction, transaction];

        return [first, second, undefined];
      } else if (cleanupTransaction.instructions.length) {
        const [second, third] = [transaction, cleanupTransaction];

        return [undefined, second, third];
      } else {
        return [undefined, transaction, undefined];
      }
    })();

    return {
      transactions: {
        setupTransaction: setupTransactionObject,
        swapTransaction: swapTransactionObject,
        cleanupTransaction: cleanupTransactionObject,
      },
      execute: async ({
        wallet,
        confirmationWaiterFactory = (txid, _totalTxs, _txDescription) => pollForConfirmedTransaction(connection, txid),
      }: ExecuteParams = {}) => {
        const sendOptions = { skipPreflight: true };
        try {
          const transactions = [setupTransactionObject, swapTransactionObject, cleanupTransactionObject].filter(
            (tx): tx is Transaction => tx !== undefined,
          );

          const totalTxs = transactions.length;

          if (owner.isKeyPair && owner.signer) {
            transactions.forEach((transaction) => {
              transaction.sign(owner.signer!);
            });
          } else {
            if (!wallet) {
              throw new Error('Signer wallet not found');
            }
            if (totalTxs > 1) {
              await wallet.signAllTransactions(transactions);
            } else {
              await wallet.signTransaction(transactions[0]);
            }
          }

          if (setupTransactionObject) {
            const setupTxid = await connection.sendRawTransaction(setupTransactionObject.serialize(), sendOptions);
            await validateTransactionResponse(setupTxid, await confirmationWaiterFactory(setupTxid, totalTxs, 'SETUP'));
          }

          try {
            let txid = await connection.sendRawTransaction(swapTransactionObject.serialize(), sendOptions);
            const transactionResponse = await validateTransactionResponse(
              txid,
              await confirmationWaiterFactory(txid, totalTxs, 'SWAP'),
            );

            const [sourceTokenBalanceChange, destinationTokenBalanceChange] =
              getTokenBalanceChangesFromTransactionResponse(
                inputMint,
                outputMint,
                sourceInstruction.address,
                instructions!.destination.address,
                transactionResponse,
              );

            return {
              txid,
              inputAddress: sourceInstruction.address,
              outputAddress: instructions!.destination.address,
              inputAmount: sourceTokenBalanceChange,
              outputAmount: destinationTokenBalanceChange,
            };
          } finally {
            if (cleanupTransactionObject) {
              const cleanupTxId = await connection.sendRawTransaction(
                cleanupTransactionObject.serialize(),
                sendOptions,
              );
              // wait for confirmation but swallow error to conserve behaviour
              await confirmationWaiterFactory(cleanupTxId, totalTxs, 'CLEANUP');
            }
          }
        } catch (error) {
          return { error: error as TransactionError };
        } finally {
          const hasOpenOrders = instructions?.openOrders.some((oo) => oo?.instructions.length);
          if (
            hasOpenOrders ||
            instructions?.intermediate?.instructions.length ||
            instructions?.destination.instructions.length
          ) {
            deleteInstructionCache({
              instructionCache: this.instructionCache,
              walletPublicKey: owner.publicKey.toBase58(),
            });
          }
          this.routeCache.clear();
        }
      },
    };
  };

  static createCreateTokenLedgerInstruction = createCreateTokenLedgerInstruction;
  static createOpenOrdersInstruction = createOpenOrdersInstruction;
  static createRaydiumSwapInstruction = createRaydiumSwapInstruction;
  static createMercurialExchangeInstruction = createMercurialExchangeInstruction;
  static createSerumSwapInstruction = createSerumSwapInstruction;
  static createSetTokenLedgerInstruction = createSetTokenLedgerInstruction;
}
