import { parseErrorForTransaction, TransactionError } from '@mercurial-finance/optimist';
import { TransactionResponse } from '@solana/web3.js';

// Throws when not found or contains error to satisfy upper control flow
export async function validateTransactionResponse(txid: string, transactionResponse: TransactionResponse | null) {
  if (!transactionResponse) {
    throw new TransactionError('Transaction was not confirmed', txid);
  }
  if (transactionResponse?.meta?.err) {
    let { message, code } = await parseErrorForTransaction(transactionResponse);

    switch (code) {
      case 6000: {
        message = 'Slippage error';
      }
    }

    throw new TransactionError(message || '', txid, code);
  }
  return transactionResponse;
}
