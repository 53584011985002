import { PublicKey, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import { AccountInfoMap, Amm, QuoteParams, SwapParams } from '../amm';
// Modified from saber's registry to contain the underlying mint decimal to avoid pointless queries
import addDecimalsJson from './add-decimals-complete-state.mainnet-beta.json';
import {
  createSaberAddDecimalsDepositInstruction,
  createSaberAddDecimalsWithdrawInstruction,
} from '../jupiterInstruction';

export interface AddDecimals {
  wrapper: PublicKey;
  underlying: PublicKey;
  underlyingDecimals: number;
  wrapperUnderlyingTokens: PublicKey;
  mint: PublicKey;
  decimals: number;
}

export function getSaberWrappedDecimalsAmms() {
  return addDecimalsJson.map((addDecimalJson) => {
    const addDecimals = {
      wrapper: new PublicKey(addDecimalJson.wrapper),
      underlying: new PublicKey(addDecimalJson.underlying),
      underlyingDecimals: addDecimalJson.underlyingDecimals,
      wrapperUnderlyingTokens: new PublicKey(addDecimalJson.wrapperUnderlyingTokens),
      mint: new PublicKey(addDecimalJson.mint),
      decimals: addDecimalJson.decimals,
    };

    return new SaberAddDecimalsAmm(new WrappedToken(addDecimals));
  });
}

export class WrappedToken {
  constructor(public addDecimals: AddDecimals) {}

  private get multiplier() {
    return 10 ** (this.addDecimals.decimals - this.addDecimals.underlyingDecimals);
  }

  getOutputAmount(inputAmount: number, inputMint: PublicKey): JSBI {
    if (this.addDecimals.mint.equals(inputMint)) {
      // withdraw, so divide
      return this.calculateWithdrawOutputAmount(inputAmount);
    } else if (this.addDecimals.underlying.equals(inputMint)) {
      // deposit, so multiply
      return this.calculateDepositOutputAmount(inputAmount);
    }
    throw new Error(`unknown input token: ${inputMint.toString()}`);
  }

  private calculateDepositOutputAmount(inputAmount: number) {
    return JSBI.multiply(JSBI.BigInt(inputAmount), JSBI.BigInt(this.multiplier));
  }

  private calculateWithdrawOutputAmount(inputAmount: number) {
    return JSBI.divide(JSBI.BigInt(inputAmount), JSBI.BigInt(this.multiplier));
  }
}

// This isn't technically an Amm but this the smoothest solution to allow its usage without a major refactor of the abstractions for now
export class SaberAddDecimalsAmm implements Amm {
  label = 'Saber (Decimals)' as const;

  constructor(public wrappedToken: WrappedToken) {}

  get id() {
    return this.wrappedToken.addDecimals.wrapper.toBase58();
  }

  getAccountsForUpdate() {
    return new Array<PublicKey>();
  }

  update(_accountInfoMap: AccountInfoMap) {}

  getQuote({ sourceMint, amount }: QuoteParams) {
    const outAmount = JSBI.toNumber(this.wrappedToken.getOutputAmount(amount, sourceMint));
    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount,
      feeAmount: 0,
      feeMint: sourceMint.toBase58(),
      feePct: 0,
      priceImpactPct: 0,
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams) {
    if (this.wrappedToken.addDecimals.underlying.equals(sourceMint)) {
      return [
        createSaberAddDecimalsDepositInstruction(
          this.wrappedToken.addDecimals,
          sourceTokenAccount,
          destinationTokenAccount,
          userTransferAuthority,
          amount,
          minimumOutAmount,
          platformFee,
        ),
      ];
    } else {
      return [
        createSaberAddDecimalsWithdrawInstruction(
          this.wrappedToken.addDecimals,
          sourceTokenAccount,
          destinationTokenAccount,
          userTransferAuthority,
          amount,
          minimumOutAmount,
          platformFee,
        ),
      ];
    }
  }

  get reserveTokenMints() {
    return [this.wrappedToken.addDecimals.underlying, this.wrappedToken.addDecimals.mint];
  }
}
