import { AccountInfo, PublicKey } from '@solana/web3.js';
import {
  ALDRIN_SWAP_V2_PROGRAM_ID,
  ALDRIN_SWAP_PROGRAM_ID,
  DEVNET_SERUM_DEX_PROGRAM,
  MAINNET_SERUM_DEX_PROGRAM,
  RAYDIUM_AMM_V4_PROGRAM_ID,
  CROPPER_PROGRAM_ID,
  SENCHA_PROGRAM_ID,
  MERCURIAL_SWAP_PROGRAM_ID,
} from '../constants';
import { Amm } from './amm';
import { SerumMarket, StableSwap } from './market';
import { SWAP_PROGRAM_ID as SABER_SWAP_PROGRAM_ID } from '@saberhq/stableswap-sdk';
import { SaberAmm } from './saber/saberAmm';
import { SerumAmm } from './serum/serumAmm';
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { AldrinAmm } from './aldrin/aldrinAmm';
import { PROGRAM_ID_TO_LABEL } from './spl-token-swap/splTokenSwapPools';
import { SplTokenSwapAmm } from './spl-token-swap/splTokenSwapAmm';
import { MercurialAmm } from './mercurial/mercurialAmm';
import { RaydiumAmm } from './raydium/raydiumAmm';
import { CropperAmm } from './cropper/cropperAmm';
import { SenchaAmm } from './sencha/senchaAmm';

export function ammFactory(address: PublicKey, accountInfo: AccountInfo<Buffer>, params?: any): Amm | undefined {
  const programId = new PublicKey(accountInfo.owner);

  if (programId.equals(MAINNET_SERUM_DEX_PROGRAM) || programId.equals(DEVNET_SERUM_DEX_PROGRAM)) {
    const decoded = SerumMarket.getLayout(programId).decode(accountInfo.data);
    if (!decoded.accountFlags.initialized || !decoded.accountFlags.market) {
      throw new Error('Invalid market');
    }
    const serumMarket = new SerumMarket(decoded, 0, 0, {}, programId);
    return new SerumAmm(serumMarket);
  } else if (programId.equals(RAYDIUM_AMM_V4_PROGRAM_ID)) {
    const raydiumAmm = new RaydiumAmm(address, accountInfo, params);
    if (raydiumAmm.status === 1) {
      return raydiumAmm;
    }
  } else if (programId.equals(MERCURIAL_SWAP_PROGRAM_ID)) {
    return new MercurialAmm(address, accountInfo, params);
  } else if (programId.equals(SABER_SWAP_PROGRAM_ID)) {
    const stableSwap = StableSwap.loadWithData(
      address,
      accountInfo.data,
      findProgramAddressSync([address.toBuffer()], SABER_SWAP_PROGRAM_ID)[0],
    );

    return new SaberAmm(stableSwap);
  } else if (programId.equals(ALDRIN_SWAP_PROGRAM_ID) || programId.equals(ALDRIN_SWAP_V2_PROGRAM_ID)) {
    return new AldrinAmm(address, accountInfo, params);
  } else if ([...PROGRAM_ID_TO_LABEL.keys()].includes(programId.toBase58())) {
    const label = PROGRAM_ID_TO_LABEL.get(accountInfo.owner.toBase58()) ?? 'Unknown';
    return new SplTokenSwapAmm(address, accountInfo, label);
  } else if (programId.equals(CROPPER_PROGRAM_ID)) {
    return new CropperAmm(address, accountInfo, params);
  } else if (programId.equals(SENCHA_PROGRAM_ID)) {
    return new SenchaAmm(address, accountInfo);
  }
  // Not supported by frontend
  return;
}
