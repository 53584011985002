import { AccountInfo, Cluster, Connection, PublicKey } from '@solana/web3.js';
import { isValidRoute, MarketInfo } from './market';
import { MARKETS_URL, WRAPPED_SOL_MINT } from '../constants';
import { RaydiumAmm } from './raydium/raydiumAmm';
import fetch from 'cross-fetch';
import { MarketMeta, TokenRouteSegments } from './types';
import { Amm } from './amm';
import { SerumAmm } from './serum/serumAmm';
import { ammFactory } from './ammFactory';
import { getTokenSwapPools } from './spl-token-swap/splTokenSwapPools';
import { getSaberWrappedDecimalsAmms, SaberAddDecimalsAmm } from './saber/saberAddDecimalsAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { getTwoPermutations } from '../utils/getTwoPermutations';

export interface TransactionFeeInfo {
  signatureFee: number;
  openOrdersDeposits: number[];
  ataDeposit: number;
  ataDepositLength: number;
}

export interface RouteInfo {
  marketInfos: MarketInfo[];
  inAmount: number;
  outAmount: number;
  outAmountWithSlippage: number;
  priceImpactPct: number;
  getDepositAndFee: () => Promise<TransactionFeeInfo | undefined>;
}

type MarketsCache = Array<
  Omit<AccountInfo<Buffer>, 'data' | 'owner'> & {
    data: [string, 'base64'];
    owner: string;
    pubkey: string;
  }
>;

type KeyedAccountInfo = AccountInfo<Buffer> & {
  pubkey: PublicKey;
  // api can pass some extra params
  params?: any;
};

export async function getAllAmms(connection: Connection, cluster: Cluster, marketUrl?: string): Promise<Amm[]> {
  const marketsCache = (await (await fetch(marketUrl || MARKETS_URL[cluster])).json()) as MarketsCache;

  const marketCacheToAccountInfo = (marketsCache: MarketsCache): Array<KeyedAccountInfo> => {
    return marketsCache.map((market) => {
      const {
        data: [accountInfo, format],
        pubkey,
        ...rest
      } = market;
      return {
        ...rest,
        pubkey: new PublicKey(pubkey),
        data: Buffer.from(accountInfo, format),
        owner: new PublicKey(rest.owner),
      };
    });
  };

  // We add market accounts infos that do not come from the API yet
  // TODO: Move to market cache
  const tokenSwapPools = getTokenSwapPools(cluster);

  const extraKeys = tokenSwapPools;
  const extraMarketKeyedAccountInfos = (await connection.getMultipleAccountsInfo(extraKeys)).reduce(
    (acc, accountInfo, index) => {
      if (accountInfo) {
        acc.push({
          ...accountInfo,
          pubkey: extraKeys[index],
        });
      }
      return acc;
    },
    new Array<KeyedAccountInfo>(),
  );

  const marketKeyedAccountInfos = marketCacheToAccountInfo(marketsCache).concat(extraMarketKeyedAccountInfos);

  const amms = marketKeyedAccountInfos.reduce((acc, keyedAccountInfo) => {
    const amm = ammFactory(keyedAccountInfo.pubkey, keyedAccountInfo, keyedAccountInfo.params);
    // Amm might not be recognized by the current version of the frontend
    // or be in a state we don't want
    if (amm) {
      acc.push(amm);
    }
    return acc;
  }, new Array<Amm>());

  const naturalAmms = amms.slice();
  amms.push(...getSaberWrappedDecimalsAmms());

  // Add the split trade Amms
  // This is very inefficient and slow
  ammCrossProtocolPairs(naturalAmms, (firstAmm, secondAmm) => {
    const splitTradeAmm = SplitTradeAmm.create(firstAmm, secondAmm);
    if (splitTradeAmm) {
      amms.push(splitTradeAmm);
    }
  });

  return amms;
}

function ammCrossProtocolPairs(arr: Amm[], func: (a: Amm, b: Amm) => void) {
  for (let i = 0; i < arr.length - 1; i++) {
    for (let j = i; j < arr.length - 1; j++) {
      // Don't pair amm with same label
      if (arr[i].label !== arr[j].label) {
        func(arr[i], arr[j + 1]);
      }
    }
  }
}

export function getTokenRouteSegments(amms: Amm[]): TokenRouteSegments {
  const tokenRouteSegments = new Map<string, Map<string, MarketMeta[]>>();

  amms.forEach((amm) => {
    const reserveTokenMintPermutations = getTwoPermutations(amm.reserveTokenMints);
    reserveTokenMintPermutations.forEach(([firstReserveMint, secondReserveMint]) => {
      addSegment(firstReserveMint.toBase58(), secondReserveMint.toBase58(), amm, tokenRouteSegments);
    });
  });

  return tokenRouteSegments;
}

function addSegment(inMint: string, outMint: string, amm: Amm, tokenRouteSegments: TokenRouteSegments) {
  let segments = tokenRouteSegments.get(inMint);

  if (!segments) {
    segments = new Map<string, MarketMeta[]>([[outMint, []]]);
    tokenRouteSegments.set(inMint, segments);
  }

  let marketMetas = segments.get(outMint);
  if (!marketMetas) {
    marketMetas = [];
    segments.set(outMint, marketMetas);
  }

  marketMetas.push({ amm });
}

export type Route = {
  marketMetas: MarketMeta[];
  intermediateMint?: PublicKey;
};

export function computeRoutes(
  inputMint: string,
  outputMint: string,
  tokenRouteSegments: TokenRouteSegments,
  intermediateTokens?: string[],
): Route[] {
  const routes: Route[] = [];
  const firstSegment = tokenRouteSegments?.get(inputMint);

  const simpleRoutes = firstSegment?.get(outputMint) ?? [];

  // Direct trade
  simpleRoutes.forEach((simpleRoute) => {
    // dont do direct decimal saber
    if (!(simpleRoute.amm instanceof SaberAddDecimalsAmm)) {
      routes.push({ marketMetas: [simpleRoute] });
    }
  });

  const secondSegment = tokenRouteSegments?.get(outputMint);

  for (const [mint, marketMetas] of firstSegment?.entries() ?? []) {
    if (intermediateTokens) {
      // if it doesnt include in the intermediateTokens, skip it
      if (!intermediateTokens.includes(mint)) {
        continue;
      }
    }
    const intersectionMarketMetas = secondSegment?.get(mint) ?? [];
    for (const marketMeta of marketMetas) {
      for (const intersectionMarketMeta of intersectionMarketMetas) {
        if (isValidRoute(marketMeta.amm, intersectionMarketMeta.amm)) {
          routes.push({
            marketMetas: [marketMeta, intersectionMarketMeta],
            intermediateMint: new PublicKey(mint),
          });
        }
      }
    }
  }

  return routes;
}

export function computeRouteMap(tokenRouteSegments: TokenRouteSegments): Map<string, string[]> {
  const routeMap = new Map<string, string[]>();

  for (const [tokenMint, firstLevelOutputs] of tokenRouteSegments) {
    const validOutputMints = new Set<string>();

    for (const [firstLevelOutputMint, firstLevelMarketMetas] of firstLevelOutputs) {
      validOutputMints.add(firstLevelOutputMint);

      // add the single level output as possible valid mints as well
      const secondLevelOutputs = tokenRouteSegments.get(firstLevelOutputMint) ?? [];
      for (const [secondLevelOutputMint, secondLevelMarketMetas] of secondLevelOutputs) {
        // Prevent output mint == input mint when routing
        if (secondLevelOutputMint === tokenMint) {
          continue;
        }

        for (const firstLevelMarketMeta of firstLevelMarketMetas) {
          for (const secondLevelMarketMeta of secondLevelMarketMetas) {
            if (isValidRoute(firstLevelMarketMeta.amm, secondLevelMarketMeta.amm)) {
              validOutputMints.add(secondLevelOutputMint);
              break;
            }
          }
        }
      }
    }
    routeMap.set(tokenMint, Array.from(validOutputMints));
  }

  return routeMap;
}

export function isSplitSetupRequired(marketInfos: MarketInfo[]): boolean {
  if (marketInfos.length === 1) {
    const amm = marketInfos[0].marketMeta.amm;
    if (amm instanceof SplitTradeAmm && amm.shouldSplitSetup) {
      return true;
    }
  } else {
    const [firstMarket, secondMarket] = marketInfos.map((marketInfo) => marketInfo.marketMeta.amm);

    if (firstMarket instanceof RaydiumAmm || secondMarket instanceof RaydiumAmm) {
      return true;
    } else if (firstMarket instanceof SerumAmm && secondMarket instanceof SerumAmm) {
      return true;
    }
  }
  return false;
}

export function getNumberOfTransactionForRoute(marketInfos: MarketInfo[]): number {
  if (isSplitSetupRequired(marketInfos)) {
    const [firstMarketInfo, secondMarketInfo] = marketInfos;

    const hasSOL = [(firstMarketInfo.inputMint, firstMarketInfo.outputMint, secondMarketInfo.outputMint)].some((item) =>
      item.equals(WRAPPED_SOL_MINT),
    );

    return hasSOL ? 3 : 2;
  }
  return 1;
}

// We cannot add platform fee to all possible routing due to transaction size limit
export function isPlatformFeeSupported(marketInfos: MarketMeta[]): boolean {
  if (marketInfos.length > 1) {
    const [firstMarket, secondMarket] = marketInfos.map((marketInfo) => marketInfo.amm);

    if (firstMarket instanceof RaydiumAmm && secondMarket instanceof RaydiumAmm) {
      return false;
    }
  }
  return true;
}

export function getRouteInfoUniqueId(routeInfo: RouteInfo) {
  return routeInfo.marketInfos.map((marketInfo) => `${marketInfo.marketMeta.amm.id}-${marketInfo.inputMint}`).join('-');
}
