import { Connection, PublicKey } from '@solana/web3.js';
import { SerumMarket } from '../market';
import { getEmptyInstruction, Instruction } from '../../utils/instruction';
import { wait } from '../../utils/wait';
import { createOpenOrdersInstruction } from '../jupiterInstruction';

export type MarketToOpenOrdersAddress = Map<string, PublicKey>;

export async function getOrCreateOpenOrdersAddress(
  connection: Connection,
  user: PublicKey,
  serumMarket: SerumMarket,
  marketToOpenOrdersAddress?: MarketToOpenOrdersAddress,
): Promise<(Instruction & { address: PublicKey }) | undefined> {
  const result = getEmptyInstruction();

  const marketAddress = serumMarket.address.toString();

  if (marketToOpenOrdersAddress) {
    // check existing map
    let openOrdersAddress = marketToOpenOrdersAddress.get(marketAddress);

    if (openOrdersAddress) {
      let openOrdersAccountInfo = null;

      // We verify if it indeed exists, with low commitment to pick it up, to address the unsafe behaviour below
      openOrdersAccountInfo = await connection.getAccountInfo(openOrdersAddress, 'processed');

      if (openOrdersAccountInfo) {
        return {
          ...result,
          address: openOrdersAddress,
        };
      }
    }
  }

  const [newOpenOrdersAddress, ix] = createOpenOrdersInstruction(serumMarket, user);

  const newOpenOrdersAddressInfo = await connection.getAccountInfo(newOpenOrdersAddress);

  if (!newOpenOrdersAddressInfo) {
    result.instructions = [ix];
  }

  // This is unsafe, since we don't know yet if it has succeeded
  marketToOpenOrdersAddress?.set(serumMarket.address.toString(), newOpenOrdersAddress);

  return {
    ...result,
    address: newOpenOrdersAddress,
  };
}
