import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, FeeCalculator, PublicKey } from '@solana/web3.js';
import type { SerumOpenOrdersMap } from '..';
import { deserializeAccount } from '../utils/deserializeAccount';
import { getCacheMintKey, getInstructionCache, setInstructionCache } from './cache';
import { RouteInfo, TransactionFeeInfo } from './routes';
import { routeAtaInstructions } from './routeToInstructions';
import { getOrCreateOpenOrdersAddress } from './serum/openOrders';
import { SerumAmm } from './serum/serumAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { InstructionCache, PlatformFeeAndAccounts, SetupInstructions } from './types';

const calculateTransactionDepositAndFee = ({
  intermediate,
  destination,
  openOrders,
  feeCalculator,
}: SetupInstructions & {
  feeCalculator: FeeCalculator;
}): TransactionFeeInfo => {
  const SERUM_OPEN_ACCOUNT_LAMPORTS = 23352760;
  const OPEN_TOKEN_ACCOUNT_LAMPORTS = 2039280;
  const openOrdersDeposits = openOrders
    .filter((ooi) => ooi && ooi.instructions.length > 0)
    .map(() => SERUM_OPEN_ACCOUNT_LAMPORTS);
  const ataDepositLength = [destination, intermediate].filter(
    (item) => item?.instructions.length && item.cleanupInstructions.length === 0,
  ).length;
  const ataDeposit = ataDepositLength * OPEN_TOKEN_ACCOUNT_LAMPORTS;

  return {
    signatureFee:
      ([destination.signers, intermediate?.signers, openOrders?.some((oo) => oo?.signers)].filter(Boolean).flat()
        .length +
        1) *
      feeCalculator.lamportsPerSignature,
    openOrdersDeposits,
    ataDeposit,
    ataDepositLength: ataDepositLength,
  };
};

export const getDepositAndFeeFromInstructions = async ({
  connection,
  marketInfos,
  userPublicKey,
  feeCalculator,
  instructionCache,
  serumOpenOrdersPromise,
  unwrapSOL,
}: {
  connection: Connection;
  userPublicKey: PublicKey;
  feeCalculator: FeeCalculator;
  marketInfos: RouteInfo['marketInfos'];
  instructionCache: InstructionCache;
  /* promise because we can choose not to await it when we dont need it */
  serumOpenOrdersPromise: Promise<SerumOpenOrdersMap>;
  unwrapSOL: boolean;
}) => {
  const cacheKey = getCacheMintKey(marketInfos);

  const walletPublicKey = userPublicKey.toBase58();

  const routeCache = getInstructionCache({
    instructionCache,
    walletPublicKey,
    cacheKey,
  });

  if (routeCache) {
    const { destination, intermediate, openOrders } = routeCache;
    return calculateTransactionDepositAndFee({
      intermediate,
      destination,
      openOrders,
      feeCalculator,
    });
  }

  const openOrdersInstructionsPromise = Promise.all(
    marketInfos.map(async (marketInfo) => {
      const amm = marketInfo.marketMeta.amm;
      if (amm instanceof SerumAmm || amm instanceof SplitTradeAmm) {
        if (!amm.market) return;
        return await getOrCreateOpenOrdersAddress(connection, userPublicKey, amm.market, await serumOpenOrdersPromise);
      }
      return;
    }),
  );

  const promise = routeAtaInstructions(connection, marketInfos, userPublicKey, unwrapSOL).then(
    ({ userIntermediaryTokenAccountResult, userDestinationTokenAccountResult }) => {
      return openOrdersInstructionsPromise.then((openOrdersInstructions) => ({
        intermediate: userIntermediaryTokenAccountResult,
        destination: userDestinationTokenAccountResult,
        openOrders: openOrdersInstructions,
      }));
    },
  );

  const instructionResult = await promise;

  setInstructionCache({
    cacheKey,
    instructionCache,
    instructionResult,
    walletPublicKey,
  });

  return calculateTransactionDepositAndFee({
    ...instructionResult,
    feeCalculator,
  });
};

export const NO_PLATFORM_FEE: PlatformFeeAndAccounts = {
  feeBps: 0,
  feeAccounts: new Map<string, PublicKey>(),
};

export async function getPlatformFeeAccounts(
  connection: Connection,
  feeAccountOwner: PublicKey,
): Promise<Map<string, PublicKey>> {
  const tokenAccounts = (
    await connection.getTokenAccountsByOwner(feeAccountOwner, {
      programId: TOKEN_PROGRAM_ID,
    })
  ).value;

  const feeAccounts = tokenAccounts.reduce((acc, tokenAccount) => {
    const deserializedtokenAccount = deserializeAccount(tokenAccount.account.data);
    if (deserializedtokenAccount) {
      acc.set(deserializedtokenAccount.mint.toBase58(), tokenAccount.pubkey);
    }
    return acc;
  }, new Map<string, PublicKey>());

  return feeAccounts;
}
