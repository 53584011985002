import type { Provider } from '@project-serum/anchor';
import { Program } from '@project-serum/anchor';
import { createProgramAddressSync, findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { Market } from '@project-serum/serum';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  AccountMeta,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { BN } from 'bn.js';
import { Jupiter as JupiterIDL, IDL } from './idl/jupiter';
import type { RaydiumAmm } from './raydium/raydiumAmm';
import { StableSwap } from '@saberhq/stableswap-sdk';
import {
  ALDRIN_SWAP_PROGRAM_ID,
  ALDRIN_SWAP_V2_PROGRAM_ID,
  RAYDIUM_AMM_V4_PROGRAM_ID,
  SABER_ADD_DECIMALS_PROGRAM_ID,
  MERCURIAL_SWAP_PROGRAM_ID,
} from '../constants';
import { AldrinPoolState } from './aldrin/poolState';
import type { TokenSwapState } from './spl-token-swap/tokenSwapLayout';
import { PlatformFee } from './types';
import type { AddDecimals } from './saber/saberAddDecimalsAmm';
import { CropperPoolState, CROPPER_STATE_ADDRESS } from './cropper/swapLayout';
import { SenchaPoolState } from './sencha/swapLayout';
import { MercurialSwapLayoutState } from './mercurial/swapLayout';

// Side rust enum used for the program's RPC API.
const Side = {
  Bid: { bid: {} },
  Ask: { ask: {} },
};

export const JUPITER_PROGRAM_ID_STAGING = new PublicKey(
  '64vBwEhR447K9tJxE4bwfkpXhgYcGz78a3AATC8sPrUS', //'JUSCvTfqyK9H9yjb64AasMc6fVpK3VsE3RCBCFz9y4Z'
);

export const JUPITER_PROGRAM_ID_PRODUCTION = new PublicKey('JUP2jxvXaqu7NQY1GmNF4m1vodw12LVXYxbFL2uJvfo');

const JUPITER_PROGRAM_ID = JUPITER_PROGRAM_ID_PRODUCTION; // JUPITER_PROGRAM_ID_PRODUCTION;

const JUPITER_PROGRAM = new Program<JupiterIDL>(IDL as JupiterIDL, JUPITER_PROGRAM_ID, {} as Provider);

const [TOKEN_LEDGER] = findProgramAddressSync([Buffer.from('token_ledger')], JUPITER_PROGRAM_ID);

function stableSwapNPoolIntoMercurialExchange(
  swayLayout: MercurialSwapLayoutState,
  sourceTokenAccount: PublicKey,
  destinationTokenAccount: PublicKey,
  user: PublicKey,
) {
  return {
    swapProgram: MERCURIAL_SWAP_PROGRAM_ID,
    swapState: swayLayout.ammId,
    tokenProgram: TOKEN_PROGRAM_ID,
    poolAuthority: swayLayout.authority,
    userTransferAuthority: user,

    sourceTokenAccount,
    destinationTokenAccount,
  };
}

function raydiumAmmToRaydiumSwap(
  raydiumAmm: RaydiumAmm,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
) {
  const [ammAuthority] = findProgramAddressSync(
    [new Uint8Array(Buffer.from('amm authority'.replace('\u00A0', ' '), 'utf-8'))],
    RAYDIUM_AMM_V4_PROGRAM_ID,
  );

  if (!raydiumAmm.serumMarketKeys) {
    throw new Error('RaydiumAmm is missing serumMarketKeys');
  }

  return {
    swapProgram: RAYDIUM_AMM_V4_PROGRAM_ID,
    tokenProgram: TOKEN_PROGRAM_ID,
    ammId: raydiumAmm.ammId,
    ammAuthority,
    ammOpenOrders: raydiumAmm.ammOpenOrders,
    poolCoinTokenAccount: raydiumAmm.poolCoinTokenAccount,
    poolPcTokenAccount: raydiumAmm.poolPcTokenAccount,
    serumProgramId: raydiumAmm.serumProgramId,
    serumMarket: raydiumAmm.serumMarket,
    serumBids: raydiumAmm.serumMarketKeys.serumBids,
    serumAsks: raydiumAmm.serumMarketKeys.serumAsks,
    serumEventQueue: raydiumAmm.serumMarketKeys.serumEventQueue,
    serumCoinVaultAccount: raydiumAmm.serumMarketKeys.serumCoinVaultAccount,
    serumPcVaultAccount: raydiumAmm.serumMarketKeys.serumPcVaultAccount,
    serumVaultSigner: raydiumAmm.serumMarketKeys.serumVaultSigner,
    userSourceTokenAccount: userSourceTokenAccountAddress,
    userDestinationTokenAccount: userDestinationTokenAccountAddress,
    userSourceOwner: user,
  };
}

function marketIntoSerumSwap(
  market: Market,
  openOrdersAddress: PublicKey,
  orderPayerTokenAccountAddress: PublicKey,
  coinWallet: PublicKey,
  pcWallet: PublicKey,
  user: PublicKey,
) {
  const vaultSigner = createProgramAddressSync(
    [market.address.toBuffer(), market.decoded.vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
    market.programId,
  );

  return {
    market: {
      market: market.address,
      openOrders: openOrdersAddress,
      requestQueue: market.decoded.requestQueue,
      eventQueue: market.decoded.eventQueue,
      bids: market.bidsAddress,
      asks: market.asksAddress,
      coinVault: market.decoded.baseVault,
      pcVault: market.decoded.quoteVault,
      vaultSigner,
    },
    authority: user,
    orderPayerTokenAccount: orderPayerTokenAccountAddress,
    coinWallet,
    pcWallet,
    // Programs.
    dexProgram: market.programId,
    tokenProgram: TOKEN_PROGRAM_ID,
    // Sysvars.
    rent: SYSVAR_RENT_PUBKEY,
  };
}

export function createMercurialExchangeInstruction(
  swapLayout: MercurialSwapLayoutState,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const remainingAccounts: AccountMeta[] = [];

  for (const swapTokenAccount of swapLayout.tokenAccounts) {
    remainingAccounts.push({
      pubkey: swapTokenAccount,
      isSigner: false,
      isWritable: true,
    });
  }
  remainingAccounts.push(...prepareRemainingAccounts(amount, platformFee?.feeAccount));

  return JUPITER_PROGRAM.instruction.mercurialExchange(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: stableSwapNPoolIntoMercurialExchange(
        swapLayout,
        userSourceTokenAccountAddress,
        userDestinationTokenAccountAddress,
        user,
      ),
      remainingAccounts,
    },
  );
}

export function createSerumSwapInstruction(
  market: Market,
  inputMint: PublicKey,
  openOrdersAddress: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
  referrer: PublicKey | undefined,
): TransactionInstruction {
  const { side, coinWallet, pcWallet } = inputMint.equals(market.baseMintAddress)
    ? {
        side: Side.Ask,
        coinWallet: userSourceTokenAccountAddress,
        pcWallet: userDestinationTokenAccountAddress,
      }
    : {
        side: Side.Bid,
        coinWallet: userDestinationTokenAccountAddress,
        pcWallet: userSourceTokenAccountAddress,
      };

  let remainingAccounts = prepareRemainingAccounts(amount, platformFee?.feeAccount);

  if (referrer) {
    remainingAccounts.push({
      pubkey: referrer,
      isSigner: false,
      isWritable: true,
    });
  }

  return JUPITER_PROGRAM.instruction.serumSwap(
    side,
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: marketIntoSerumSwap(
        market,
        openOrdersAddress,
        userSourceTokenAccountAddress,
        coinWallet,
        pcWallet,
        user,
      ),
      remainingAccounts,
    },
  );
}

export function createTokenSwapInstruction(
  tokenSwapState: TokenSwapState,
  inputMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
  isStep: boolean,
): TransactionInstruction {
  const [swapSource, swapDestination] = inputMint.equals(tokenSwapState.mintA)
    ? [tokenSwapState.tokenAccountA, tokenSwapState.tokenAccountB]
    : [tokenSwapState.tokenAccountB, tokenSwapState.tokenAccountA];

  return (isStep ? JUPITER_PROGRAM.instruction.stepTokenSwap : JUPITER_PROGRAM.instruction.tokenSwap)(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        tokenSwapProgram: tokenSwapState.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        swap: tokenSwapState.address,
        authority: tokenSwapState.authority,
        userTransferAuthority: user,
        source: userSourceTokenAccountAddress,
        swapSource,
        swapDestination,
        destination: userDestinationTokenAccountAddress,
        poolMint: tokenSwapState.poolToken,
        poolFee: tokenSwapState.feeAccount,
      },
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createSenchaSwapInstruction(
  poolState: SenchaPoolState,
  sourceMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(poolState.token0Mint)
    ? [poolState.token0Reserves, poolState.token1Reserves]
    : [poolState.token1Reserves, poolState.token0Reserves];

  const [feesSource, feesDestination] = sourceMint.equals(poolState.token0Mint)
    ? [poolState.token0Fees, poolState.token1Fees]
    : [poolState.token1Fees, poolState.token0Fees];

  return JUPITER_PROGRAM.instruction.senchaExchange(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        swapProgram: poolState.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        swap: poolState.ammId,
        userAuthority: user,
        inputUserAccount: userSourceTokenAccountAddress,
        inputTokenAccount: swapSource,
        inputFeesAccount: feesSource,
        outputUserAccount: userDestinationTokenAccountAddress,
        outputTokenAccount: swapDestination,
        outputFeesAccount: feesDestination,
      },
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createCropperSwapInstruction(
  poolState: CropperPoolState,
  sourceMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  feeAccount: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(poolState.mintA)
    ? [poolState.tokenAAccount, poolState.tokenBAccount]
    : [poolState.tokenBAccount, poolState.tokenAAccount];

  return JUPITER_PROGRAM.instruction.cropperTokenSwap(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        tokenSwapProgram: poolState.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        swap: poolState.ammId,
        swapState: CROPPER_STATE_ADDRESS,
        authority: poolState.authority,
        userTransferAuthority: user,
        source: userSourceTokenAccountAddress,
        swapSource,
        swapDestination,
        destination: userDestinationTokenAccountAddress,
        poolMint: poolState.poolMint,
        poolFee: feeAccount,
      },
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createRaydiumSwapInstruction(
  raydiumAmm: RaydiumAmm,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.raydiumSwapV2(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: raydiumAmmToRaydiumSwap(
        raydiumAmm,
        userSourceTokenAccountAddress,
        userDestinationTokenAccountAddress,
        user,
      ),
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createAldrinSwapInstruction(
  poolState: AldrinPoolState,
  sourceMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const [side, userBaseTokenAccount, userQuoteTokenAccount] = sourceMint.equals(poolState.baseTokenMint)
    ? [Side.Ask, userSourceTokenAccountAddress, userDestinationTokenAccountAddress]
    : [Side.Bid, userDestinationTokenAccountAddress, userSourceTokenAccountAddress];

  return JUPITER_PROGRAM.instruction.aldrinSwap(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    side,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        swapProgram: ALDRIN_SWAP_PROGRAM_ID,
        pool: poolState.address,
        poolSigner: poolState.poolSigner,
        poolMint: poolState.poolMint,
        baseTokenVault: poolState.baseTokenVault,
        quoteTokenVault: poolState.quoteTokenVault,
        feePoolTokenAccount: poolState.feePoolTokenAccount,
        walletAuthority: user,
        userBaseTokenAccount,
        userQuoteTokenAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createAldrinV2SwapInstruction(
  poolState: AldrinPoolState,
  sourceMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  curve: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const [side, userBaseTokenAccount, userQuoteTokenAccount] = sourceMint.equals(poolState.baseTokenMint)
    ? [Side.Ask, userSourceTokenAccountAddress, userDestinationTokenAccountAddress]
    : [Side.Bid, userDestinationTokenAccountAddress, userSourceTokenAccountAddress];

  return JUPITER_PROGRAM.instruction.aldrinV2Swap(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    side,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        swapProgram: ALDRIN_SWAP_V2_PROGRAM_ID,
        pool: poolState.address,
        poolSigner: poolState.poolSigner,
        poolMint: poolState.poolMint,
        baseTokenVault: poolState.baseTokenVault,
        quoteTokenVault: poolState.quoteTokenVault,
        feePoolTokenAccount: poolState.feePoolTokenAccount,
        walletAuthority: user,
        userBaseTokenAccount,
        userQuoteTokenAccount,
        curve,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      remainingAccounts: prepareRemainingAccounts(amount, platformFee?.feeAccount),
    },
  );
}

export function createRiskCheckAndFeeInstruction(
  userDestinationTokenAccount: PublicKey,
  userTransferAuthority: PublicKey,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const remainingAccounts: AccountMeta[] = [];

  if (platformFee?.feeAccount) {
    remainingAccounts.push({
      pubkey: platformFee.feeAccount,
      isSigner: false,
      isWritable: true,
    });
  }

  return JUPITER_PROGRAM.instruction.riskCheckAndFee(new BN(minimumOutAmount), platformFee?.feeBps ?? 0, {
    accounts: {
      tokenLedger: TOKEN_LEDGER,
      userDestinationTokenAccount,
      userTransferAuthority,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts,
  });
}

export function createSetTokenLedgerInstruction(tokenAccountAddress: PublicKey): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.setTokenLedger({
    accounts: {
      tokenLedger: TOKEN_LEDGER,
      tokenAccount: tokenAccountAddress,
    },
  });
}

export function createCreateTokenLedgerInstruction(user: PublicKey): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.initializeTokenLedger({
    accounts: {
      tokenLedger: TOKEN_LEDGER,
      payer: user,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
    },
  });
}

export function createOpenOrdersInstruction(market: Market, user: PublicKey): [PublicKey, TransactionInstruction] {
  const [openOrders] = findProgramAddressSync(
    [Buffer.from('open_orders'), market.publicKey.toBuffer(), user.toBuffer()],
    JUPITER_PROGRAM_ID,
  );

  const ix = JUPITER_PROGRAM.instruction.createOpenOrders({
    accounts: {
      openOrders,
      payer: user,
      dexProgram: market.programId,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
      market: market.publicKey,
    },
  });
  return [openOrders, ix];
}

function saberPoolIntoSaberExchange(
  saberPool: StableSwap,
  sourceMintAddress: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
) {
  const feesTokenAccount = sourceMintAddress.equals(saberPool.state.tokenA.mint)
    ? saberPool.state.tokenB.adminFeeAccount
    : saberPool.state.tokenA.adminFeeAccount;
  const [inputTokenAccount, outputTokenAccount] = sourceMintAddress.equals(saberPool.state.tokenA.mint)
    ? [saberPool.state.tokenA.reserve, saberPool.state.tokenB.reserve]
    : [saberPool.state.tokenB.reserve, saberPool.state.tokenA.reserve];

  return {
    swapProgram: saberPool.config.swapProgramID,
    tokenProgram: TOKEN_PROGRAM_ID,
    swap: saberPool.config.swapAccount,
    swapAuthority: saberPool.config.authority,
    userAuthority: user,
    clock: SYSVAR_CLOCK_PUBKEY,
    inputUserAccount: userSourceTokenAccountAddress,
    inputTokenAccount,
    outputUserAccount: userDestinationTokenAccountAddress,
    outputTokenAccount,
    feesTokenAccount,
  };
}

export function createSaberExchangeInstruction(
  saberPool: StableSwap,
  inputMint: PublicKey,
  userSourceTokenAccountAddress: PublicKey,
  userDestinationTokenAccountAddress: PublicKey,
  user: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const remainingAccounts = prepareRemainingAccounts(amount, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberExchange(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: saberPoolIntoSaberExchange(
        saberPool,
        inputMint,
        userSourceTokenAccountAddress,
        userDestinationTokenAccountAddress,
        user,
      ),
      remainingAccounts,
    },
  );
}

export function createSaberAddDecimalsDepositInstruction(
  addDecimals: AddDecimals,
  sourceTokenAccountAddress: PublicKey,
  destinationTokenAccountAddress: PublicKey,
  userTransferAuthority: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
) {
  const remainingAccounts = prepareRemainingAccounts(amount, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberAddDecimalsDeposit(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        addDecimalsProgram: SABER_ADD_DECIMALS_PROGRAM_ID,
        wrapper: addDecimals.wrapper,
        wrapperMint: addDecimals.mint,
        wrapperUnderlyingTokens: addDecimals.wrapperUnderlyingTokens,
        owner: userTransferAuthority,
        userUnderlyingTokens: sourceTokenAccountAddress,
        userWrappedTokens: destinationTokenAccountAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      remainingAccounts,
    },
  );
}

export function createSaberAddDecimalsWithdrawInstruction(
  addDecimals: AddDecimals,
  sourceTokenAccountAddress: PublicKey,
  destinationTokenAccountAddress: PublicKey,
  userTransferAuthority: PublicKey,
  amount: number | null,
  minimumOutAmount: number,
  platformFee: PlatformFee | undefined,
) {
  const remainingAccounts = prepareRemainingAccounts(amount, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberAddDecimalsWithdraw(
    amount ? new BN(amount) : amount,
    new BN(minimumOutAmount),
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        addDecimalsProgram: SABER_ADD_DECIMALS_PROGRAM_ID,
        wrapper: addDecimals.wrapper,
        wrapperMint: addDecimals.mint,
        wrapperUnderlyingTokens: addDecimals.wrapperUnderlyingTokens,
        owner: userTransferAuthority,
        userUnderlyingTokens: destinationTokenAccountAddress,
        userWrappedTokens: sourceTokenAccountAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      remainingAccounts,
    },
  );
}

function prepareRemainingAccounts(amount: number | null, feeAccount: PublicKey | undefined): AccountMeta[] {
  const remainingAccounts = [];

  if (amount === null) {
    remainingAccounts.push({
      pubkey: TOKEN_LEDGER,
      isSigner: false,
      isWritable: true,
    });
  }
  if (feeAccount) {
    remainingAccounts.push({
      pubkey: feeAccount,
      isSigner: false,
      isWritable: true,
    });
  }

  return remainingAccounts;
}
