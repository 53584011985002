import JSBI from 'jsbi';
import { Fraction } from '../fraction';
import { ZERO, ONE } from '../utils';

/** calculates fee amount given a fee fraction, returning one when fee is set but amount is zero */
export function calculateFeeAmount(amount: JSBI, fee: Fraction): JSBI {
  if (JSBI.equal(fee.numerator, ZERO) || JSBI.equal(amount, ZERO)) {
    return ZERO;
  } else {
    const feeAmount = JSBI.divide(JSBI.multiply(amount, fee.numerator), fee.denominator);
    if (JSBI.equal(feeAmount, ZERO)) {
      return ONE;
    }
    return feeAmount;
  }
}
