import { Market as SerumMarket } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import { StableSwap } from '@saberhq/stableswap-sdk';
import { MarketMeta } from './types';
import { SaberAddDecimalsAmm } from './saber/saberAddDecimalsAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';

export interface Fee {
  amount: number;
  mint: string;
  pct: number;
}

export { SerumMarket, StableSwap };

export interface MarketInfo {
  marketMeta: MarketMeta; //name: string, // pair for serum, pool name for mer pool
  inputMint: PublicKey;
  outputMint: PublicKey;
  minInAmount?: number;
  minOutAmount?: number;
  inAmount: number;
  outAmount: number;
  notEnoughLiquidity: boolean;
  priceImpactPct: number;
  lpFee: Fee;
  platformFee: Fee;
}

// This is to check if the two markets are supported by the aggregator
export const isValidRoute = (ammA: MarketMeta['amm'], ammB: MarketMeta['amm']) => {
  // don't show decimal as input or output
  if (ammA instanceof SaberAddDecimalsAmm && ammB instanceof SaberAddDecimalsAmm) {
    return false;
  } else if (ammA instanceof SplitTradeAmm || ammB instanceof SplitTradeAmm) {
    return false;
  }

  return true;
};
