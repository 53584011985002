// Only a few hardcoded pools for now, until we query the whole lot through api
import { struct, nu64 } from '@solana/buffer-layout';
import { u64 } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { publicKey, uint128, uint64 } from '../../utils/layout';

interface AmmInfoLayoutV4 {
  status: number;
  nonce: number;
  orderNum: number;
  depth: number;
  coinDecimals: number;
  pcDecimals: number;
  state: number;
  resetFlag: number;
  minSize: number;
  volMaxCutRatio: number;
  amountWaveRatio: number;
  coinLotSize: number;
  pcLotSize: number;
  minPriceMultiplier: number;
  maxPriceMultiplier: number;
  systemDecimalsValue: number;
  minSeparateNumerator: number;
  minSeparateDenominator: number;
  tradeFeeNumerator: number;
  tradeFeeDenominator: number;
  pnlNumerator: number;
  pnlDenominator: number;
  swapFeeNumerator: u64;
  swapFeeDenominator: u64;
  needTakePnlCoin: number;
  needTakePnlPc: number;
  totalPnlPc: number;
  totalPnlCoin: number;
  poolTotalDepositPc: u64;
  poolTotalDepositCoin: u64;
  swapCoinInAmount: u64;
  swapPcOutAmount: u64;
  swapCoin2PcFee: number;
  swapPcInAmount: u64;
  swapCoinOutAmount: u64;
  swapPc2CoinFee: number;
  poolCoinTokenAccount: PublicKey;
  poolPcTokenAccount: PublicKey;
  coinMintAddress: PublicKey;
  pcMintAddress: PublicKey;
  lpMintAddress: PublicKey;
  ammOpenOrders: PublicKey;
  serumMarket: PublicKey;
  serumProgramId: PublicKey;
  ammTargetOrders: PublicKey;
  poolWithdrawQueue: PublicKey;
  poolTempLpTokenAccount: PublicKey;
  ammOwner: PublicKey;
  pnlOwner: PublicKey;
}

export const AMM_INFO_LAYOUT_V4 = struct<AmmInfoLayoutV4>([
  nu64('status'),
  nu64('nonce'),
  nu64('orderNum'),
  nu64('depth'),
  nu64('coinDecimals'),
  nu64('pcDecimals'),
  nu64('state'),
  nu64('resetFlag'),
  nu64('minSize'),
  nu64('volMaxCutRatio'),
  nu64('amountWaveRatio'),
  nu64('coinLotSize'),
  nu64('pcLotSize'),
  nu64('minPriceMultiplier'),
  nu64('maxPriceMultiplier'),
  nu64('systemDecimalsValue'),
  // Fees
  nu64('minSeparateNumerator'),
  nu64('minSeparateDenominator'),
  nu64('tradeFeeNumerator'),
  nu64('tradeFeeDenominator'),
  nu64('pnlNumerator'),
  nu64('pnlDenominator'),
  nu64('swapFeeNumerator'),
  nu64('swapFeeDenominator'),
  // OutPutData
  nu64('needTakePnlCoin'),
  nu64('needTakePnlPc'),
  nu64('totalPnlPc'),
  nu64('totalPnlCoin'),
  uint128('poolTotalDepositPc'),
  uint128('poolTotalDepositCoin'),
  uint128('swapCoinInAmount'),
  uint128('swapPcOutAmount'),
  nu64('swapCoin2PcFee'),
  uint128('swapPcInAmount'),
  uint128('swapCoinOutAmount'),
  nu64('swapPc2CoinFee'),

  publicKey('poolCoinTokenAccount'),
  publicKey('poolPcTokenAccount'),
  publicKey('coinMintAddress'),
  publicKey('pcMintAddress'),
  publicKey('lpMintAddress'),
  publicKey('ammOpenOrders'),
  publicKey('serumMarket'),
  publicKey('serumProgramId'),
  publicKey('ammTargetOrders'),
  publicKey('poolWithdrawQueue'),
  publicKey('poolTempLpTokenAccount'),
  publicKey('ammOwner'),
  publicKey('pnlOwner'),
]);
