import { SystemProgram, TransactionResponse } from '@solana/web3.js';

type CustomInstructionError = [index: number, code: { Custom: number }];
interface ITransactionError {
  InstructionError: CustomInstructionError;
}

export class TransactionError extends Error {
  constructor(m: string, public txid?: string, public code?: number) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, Error.prototype);
  }
}
const UNKNOWN_ERROR = 'Unknown error, visit the explorer';

export async function parseErrorForTransaction(tx: TransactionResponse): Promise<{ message: string; code?: number }> {
  // Easy case, logMessages has an obvious error message. From dapp-scaffold
  const errors: string[] = [];
  if (tx?.meta && tx.meta.logMessages) {
    tx.meta.logMessages.forEach((log) => {
      const regex = /Error: (.*)/gm;
      let m;
      while ((m = regex.exec(log)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        if (m.length > 1) {
          errors.push(m[1]);
        }
      }
    });
  }

  if (errors.length > 0) {
    return { message: errors.join(',') };
  }

  // Harder case, we need to dig for a custom code
  const transactionError = tx?.meta?.err;
  const transactionErrorString = JSON.stringify(transactionError);
  let errorCode;

  if (transactionError && typeof transactionError !== 'string') {
    const instructionError = (transactionError as ITransactionError).InstructionError;

    const [index, { Custom }] = instructionError;
    errorCode = Custom;

    // We have found what caused the error so we can map it
    const innerInstructions = tx?.meta?.innerInstructions;
    if (!innerInstructions || index >= innerInstructions.length) {
      if (tx?.meta && tx.meta.logMessages && isSystemProgram(tx.meta?.logMessages)) {
        return getSystemProgramError(instructionError);
      }

      return { message: transactionErrorString, code: errorCode };
    }
    const innerInstruction = innerInstructions.find((innerIx) => innerIx.index === index);
    if (!innerInstruction) {
      return { message: transactionErrorString, code: errorCode };
    }

    const programIdIndex = innerInstruction.instructions[innerInstruction.instructions.length - 1].programIdIndex;
    const programId = tx.transaction.message.accountKeys[programIdIndex];
    if (programId.equals(SystemProgram.programId)) {
      return getSystemProgramError(instructionError);
    }
  }

  return { message: typeof transactionError === 'string' ? transactionError : UNKNOWN_ERROR, code: errorCode };
}

async function isSystemProgram(logMessages: String[]) {
  logMessages.forEach((log) => {
    if (log.includes('Program 11111111111111111111111111111111 invoke')) return true;
  });

  return false;
}

async function getSystemProgramError(instructionError: CustomInstructionError) {
  const code = instructionError[1].Custom;

  let message = '';
  switch (code) {
    // https://github.com/solana-labs/solana/blob/22a18a68e3ee68ae013d647e62e12128433d7230/sdk/program/src/system_instruction.rs#L12-L26
    // TODO: Do we need to translate all error codes.
    case 0:
      message = 'An account with the same address already exists';
    case 1:
      message = 'The account does not have enough SOL to perform the operation';
    default:
      message = UNKNOWN_ERROR;
  }

  return {
    code,
    message,
  };
}
