import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { PlatformFee, QuoteMintToReferrer, TokenMintAddress } from '..';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import JSBI from 'jsbi';

export interface QuoteParams {
  sourceMint: PublicKey;
  destinationMint: PublicKey;
  amount: number;
}

export interface Quote {
  notEnoughLiquidity: boolean;
  minInAmount?: number;
  minOutAmount?: number;
  inAmount: number;
  outAmount: number;
  feeAmount: number;
  feeMint: TokenMintAddress;
  feePct: number;
  priceImpactPct: number;
}

export interface SwapParams {
  sourceMint: PublicKey;
  destinationMint: PublicKey;
  sourceTokenAccount: PublicKey;
  destinationTokenAccount: PublicKey;
  userTransferAuthority: PublicKey;
  amount: number | null;
  minimumOutAmount: number;
  openOrdersAddress?: PublicKey;
  platformFee?: PlatformFee;
  quoteMintToReferrer?: QuoteMintToReferrer;
}

export type AccountInfoMap = Map<string, AccountInfo<Buffer> | null>;

export interface Amm {
  /* Label for UI usage */
  label: string;
  /* Unique id to recognize the AMM */
  id: string;
  /* Reserve token mints for the purpose of routing */
  reserveTokenMints: PublicKey[];

  getAccountsForUpdate(): PublicKey[];
  update(accountInfoMap: AccountInfoMap): void;

  getQuote(quoteParams: QuoteParams): Quote;

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[];
}

export const mapAddressToAccountInfos = (accountInfoMap: AccountInfoMap, addresses: PublicKey[]) => {
  const accountInfos = addresses.map((address) => {
    const accountInfo = accountInfoMap.get(address.toString());
    if (!accountInfo) {
      throw new Error(`Account info ${address.toBase58()} missing`);
    }
    return accountInfo;
  });

  return accountInfos;
};

export const tokenAccountsToJSBIs = (tokenAccounts: TokenAccountInfo[]): JSBI[] => {
  return tokenAccounts.map((tokenAccount) => {
    return JSBI.BigInt(tokenAccount.amount);
  });
};
