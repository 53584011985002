import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { struct, u8, u32 } from '@solana/buffer-layout';
import { u64 } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import { uint64, publicKey } from '../../utils/layout';

export const FEE_DENOMINATOR = Math.pow(10, 10);

interface MercurialSwapLayout {
  version: number;
  isInitialized: number;
  nonce: number;
  amplificationCoefficient: u64;
  feeNumerator: u64;
  adminFeeNumerator: u64;
  tokenAccountsLength: number;
  precisionFactor: u64;
  precisionMultiplierA: u64;
  precisionMultiplierB: u64;
  precisionMultiplierC: u64;
  precisionMultiplierD: u64;
  tokenAccountA: PublicKey;
  tokenAccountB: PublicKey;
  tokenAccountC: PublicKey;
  tokenAccountD: PublicKey;
}

export const MercurialSwapLayout = struct<MercurialSwapLayout>([
  u8('version'),
  u8('isInitialized'),
  u8('nonce'),
  uint64('amplificationCoefficient'),
  uint64('feeNumerator'),
  uint64('adminFeeNumerator'),
  u32('tokenAccountsLength'),
  uint64('precisionFactor'),
  uint64('precisionMultiplierA'),
  uint64('precisionMultiplierB'),
  uint64('precisionMultiplierC'),
  uint64('precisionMultiplierD'),
  publicKey('tokenAccountA'),
  publicKey('tokenAccountB'),
  publicKey('tokenAccountC'),
  publicKey('tokenAccountD'),
]);

export interface MercurialSwapLayoutState {
  programId: PublicKey;
  authority: PublicKey;
  isInitialized: boolean;
  nonce: number;
  ammId: PublicKey;
  amplificationCoefficient: number;
  feeNumerator: number;
  tokenAccountsLength: number;
  precisionFactor: number;
  precisionMultipliers: number[];
  tokenAccounts: PublicKey[];
}

export const accountInfoToMercurialSwapLayout = (
  address: PublicKey,
  accountInfo: AccountInfo<Buffer>,
): MercurialSwapLayoutState => {
  const programId = accountInfo.owner;
  const decoded = MercurialSwapLayout.decode(accountInfo.data);
  const tokenAccountsLength = decoded.tokenAccountsLength;
  const [authority] = findProgramAddressSync([address.toBuffer()], programId);

  const precisionMultipliers = [
    decoded.precisionMultiplierA.toNumber(),
    decoded.precisionMultiplierB.toNumber(),
    decoded.precisionMultiplierC.toNumber(),
    decoded.precisionMultiplierD.toNumber(),
  ].slice(0, tokenAccountsLength);

  const tokenAccounts = [
    decoded.tokenAccountA,
    decoded.tokenAccountB,
    decoded.tokenAccountC,
    decoded.tokenAccountD,
  ].slice(0, tokenAccountsLength);

  return {
    programId,
    authority,
    isInitialized: Boolean(decoded.isInitialized),
    nonce: decoded.nonce,
    ammId: address,
    amplificationCoefficient: decoded.amplificationCoefficient.toNumber(),
    feeNumerator: decoded.feeNumerator.toNumber(),
    tokenAccountsLength,
    precisionFactor: decoded.precisionFactor.toNumber(),
    precisionMultipliers,
    tokenAccounts,
  };
};
