import { Orderbook } from '@project-serum/serum';
import { PublicKey, TransactionInstruction } from '@solana/web3.js';
import { AccountInfoMap, Amm, mapAddressToAccountInfos, Quote, QuoteParams, SwapParams } from '../amm';
import { createSerumSwapInstruction } from '../jupiterInstruction';
import { SerumMarket } from '../market';
import { getOutAmountMeta } from './market';

export class SerumAmm implements Amm {
  label = 'Serum' as const;
  private orderbooks: { asks: Orderbook; bids: Orderbook } | undefined;

  constructor(public market: SerumMarket) {}

  get id() {
    return this.market.address.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.market.asksAddress, this.market.bidsAddress];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const [asksAccountInfo, bidsAccountInfo] = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    const asks = Orderbook.decode(this.market, asksAccountInfo.data);
    const bids = Orderbook.decode(this.market, bidsAccountInfo.data);

    this.orderbooks = {
      asks,
      bids,
    };
  }

  getQuote({ sourceMint, destinationMint, amount }: QuoteParams): Quote {
    if (!this.orderbooks) {
      throw new Error('Failed to find orderbooks');
    }

    const outAmountMeta = getOutAmountMeta({
      market: this.market,
      asks: this.orderbooks.asks,
      bids: this.orderbooks.bids,
      fromMint: sourceMint,
      toMint: destinationMint,
      fromAmount: amount,
    });

    return {
      notEnoughLiquidity: outAmountMeta.notEnoughLiquidity,
      minInAmount: outAmountMeta.minimum.in,
      minOutAmount: outAmountMeta.minimum.out,
      inAmount: outAmountMeta.inAmount,
      outAmount: outAmountMeta.outAmount,
      feeAmount: outAmountMeta.feeAmount,
      feeMint: this.market.quoteMintAddress.toBase58(),
      feePct: outAmountMeta.feePct,
      priceImpactPct: outAmountMeta.priceImpactPct,
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    openOrdersAddress,
    platformFee,
    quoteMintToReferrer,
  }: SwapParams) {
    if (!openOrdersAddress) {
      throw new Error('Missing open orders');
    }

    return [
      createSerumSwapInstruction(
        this.market,
        sourceMint,
        openOrdersAddress,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        amount,
        minimumOutAmount,
        platformFee,
        quoteMintToReferrer?.get(this.market.quoteMintAddress.toBase58()),
      ),
    ];
  }

  get reserveTokenMints() {
    return [this.market.baseMintAddress, this.market.quoteMintAddress];
  }
}
