import { struct, blob, u8, Structure } from '@solana/buffer-layout';
import { u64 } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import { ALDRIN_SWAP_V2_PROGRAM_ID } from '../../constants';
import { publicKey, rustEnum, uint64 } from '../../utils/layout';
import { Percentage } from './percentage';

type FeeStructure = {
  traderFee: Percentage;
  ownerFee: Percentage;
};

interface FeeLayout {
  tradeFeeNumerator: u64;
  tradeFeeDenominator: u64;
  ownerTradeFeeNumerator: u64;
  ownerTradeFeeDenominator: u64;
  ownerWithdrawFeeNumerator: u64;
  ownerWithdrawFeeDenominator: u64;
}

// https://github.com/aldrin-exchange/aldrin-sdk/blob/78527e3adfc02e186956f420b5083fa0950e7263/src/pools/layout.ts
const FEES_LAYOUT = struct<FeeLayout>(
  [
    uint64('tradeFeeNumerator'),
    uint64('tradeFeeDenominator'),
    uint64('ownerTradeFeeNumerator'),
    uint64('ownerTradeFeeDenominator'),
    uint64('ownerWithdrawFeeNumerator'),
    uint64('ownerWithdrawFeeDenominator'),
  ],
  'fees',
);

const POOL_FIELDS_COMMON = [
  blob(8, 'padding'),
  publicKey('lpTokenFreezeVault'),
  publicKey('poolMint'),
  publicKey('baseTokenVault'),
  publicKey('baseTokenMint'),
  publicKey('quoteTokenVault'),
  publicKey('quoteTokenMint'),
  publicKey('poolSigner'),
  u8('poolSignerNonce'),
  publicKey('authority'),
  publicKey('initializerAccount'),
  publicKey('feeBaseAccount'),
  publicKey('feeQuoteAccount'),
  publicKey('feePoolTokenAccount'),
  FEES_LAYOUT,
];

interface PoolLayout {
  padding: any;
  lpTokenFreezeVault: PublicKey;
  poolMint: PublicKey;
  baseTokenVault: PublicKey;
  baseTokenMint: PublicKey;
  quoteTokenVault: PublicKey;
  quoteTokenMint: PublicKey;
  poolSigner: PublicKey;
  poolSignerNonce: number;
  authority: PublicKey;
  initializerAccount: PublicKey;
  feeBaseAccount: PublicKey;
  feeQuoteAccount: PublicKey;
  feePoolTokenAccount: PublicKey;
  fees: FeeLayout;
}

export const POOL_LAYOUT = struct<PoolLayout>(POOL_FIELDS_COMMON);

interface PoolV2Layout extends PoolLayout {
  curveType: number;
  curve: PublicKey;
}

export const POOL_V2_LAYOUT = struct<PoolV2Layout>([...POOL_FIELDS_COMMON, u8('curveType'), publicKey('curve')]);

interface StableCurveLayout {
  padding: any;
  amp: u64;
}

export const STABLE_CURVE_LAYOUT = struct<StableCurveLayout>([blob(8, 'padding'), uint64('amp')]);

interface SwapInstructionLayout {
  instruction: any;
  tokens: u64;
  minTokens: u64;
  side: 'bid' | 'ask';
}

export const SWAP_INSTRUCTION_LAYOUT = struct<SwapInstructionLayout>([
  blob(8, 'instruction'),
  uint64('tokens'),
  uint64('minTokens'),
  rustEnum([new Structure([], 'bid'), new Structure([], 'ask')], 'side'),
]);

export function accountInfoToAldrinPoolState(address: PublicKey, accountInfo: AccountInfo<Buffer>): AldrinPoolState {
  const isV2 = accountInfo.owner.equals(ALDRIN_SWAP_V2_PROGRAM_ID) ? true : false;

  const decoded: PoolV2Layout | PoolLayout = (isV2 ? POOL_V2_LAYOUT : POOL_LAYOUT).decode(accountInfo.data) as
    | PoolV2Layout
    | PoolLayout;

  const curveObject = 'curveType' in decoded ? { curveType: decoded.curveType, curve: decoded.curve } : {};

  return {
    isV2,
    address,
    poolMint: decoded.poolMint,
    baseTokenVault: decoded.baseTokenVault,
    baseTokenMint: decoded.baseTokenMint,
    quoteTokenVault: decoded.quoteTokenVault,
    quoteTokenMint: decoded.quoteTokenMint,
    poolSigner: decoded.poolSigner,
    feeBaseAccount: decoded.feeBaseAccount,
    feeQuoteAccount: decoded.feeQuoteAccount,
    feePoolTokenAccount: decoded.feePoolTokenAccount,
    fees: {
      traderFee: Percentage.fromFraction(decoded.fees.tradeFeeNumerator, decoded.fees.tradeFeeDenominator),
      ownerFee: Percentage.fromFraction(decoded.fees.ownerTradeFeeNumerator, decoded.fees.ownerTradeFeeDenominator),
    },
    ...curveObject,
  };
}

export interface AldrinPoolState {
  isV2: Boolean;
  address: PublicKey;
  poolMint: PublicKey;
  baseTokenVault: PublicKey;
  baseTokenMint: PublicKey;
  quoteTokenVault: PublicKey;
  quoteTokenMint: PublicKey;
  poolSigner: PublicKey;
  feeBaseAccount: PublicKey;
  feeQuoteAccount: PublicKey;
  feePoolTokenAccount: PublicKey;
  fees: FeeStructure;
  curveType?: number;
  curve?: PublicKey;
}
