import { Cluster, PublicKey } from '@solana/web3.js';

export const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112');

export const MAINNET_SERUM_DEX_PROGRAM = new PublicKey('9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin');
export const DEVNET_SERUM_DEX_PROGRAM = new PublicKey('DESVgJVGajEgKGXhb6XmqDHGz3VjdgP7rEVESBgxmroY');

export const MARKETS_URL: Record<Cluster, string> = {
  devnet: 'https://api.jup.ag/api/markets/cache/devnet',
  'mainnet-beta': 'https://cache.jup.ag/markets?v=3',
  testnet: 'https://api.jup.ag/api/markets/cache/devnet',
};

export const TOKEN_LIST_URL: Record<Cluster, string> = {
  devnet: 'https://api.jup.ag/api/tokens/devnet',
  testnet: 'https://api.jup.ag/api/markets/devnet',
  'mainnet-beta': 'https://cache.jup.ag/tokens',
};

export const LAMPORTS_PER_SIGNATURE = 5000;

export const RAYDIUM_AMM_V4_PROGRAM_ID = new PublicKey('675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8');

export const ALDRIN_SWAP_PROGRAM_ID = new PublicKey('AMM55ShdkoGRB5jVYPjWziwk8m5MpwyDgsMWHaMSQWH6');

// https://github.com/aldrin-exchange/aldrin-sdk/blob/f93fe3f2d847d79d8ddff507d8d4f62fd803421b/src/v2.json
export const ALDRIN_SWAP_V2_PROGRAM_ID = new PublicKey('CURVGoZn8zycx6FXwwevgBTB2gVvdbGTEpvMJDbgs2t4');

export const SABER_ADD_DECIMALS_PROGRAM_ID = new PublicKey('DecZY86MU5Gj7kppfUCEmd4LbXXuyZH1yHaP2NTqdiZB');

export const CROPPER_PROGRAM_ID = new PublicKey('CTMAxxk34HjKWxQ3QLZK1HpaLXmBveao3ESePXbiyfzh');

export const SENCHA_PROGRAM_ID = new PublicKey('SCHAtsf8mbjyjiv4LkhLKutTf6JnZAbdJKFkXQNMFHZ');

export const JUPITER_WALLET = new PublicKey('BUX7s2ef2htTGb2KKoPHWkmzxPj4nTWMWRgs5CSbQxf9');

export const MERCURIAL_SWAP_PROGRAM_ID = new PublicKey('MERLuDFBMmsHnsBPZw2sDQZHvXFMwp8EdjudcU2HKky');
