import JSBI from 'jsbi';
import { Curve } from '../math/curve';
import { SwapResult, toDecimal, ZERO } from '../utils';
import Decimal from 'decimal.js';
import { Fraction } from '..';

export class Stable {
  private curve: Curve;

  constructor(numberOfCurrencies: JSBI, amp: JSBI, private targetPrices: JSBI[], private traderFee: Fraction) {
    this.curve = new Curve(numberOfCurrencies, amp, this.targetPrices);
  }

  public exchange(tokenAmounts: JSBI[], inputTradeAmount: JSBI, inputIndex: number, outputIndex: number): SwapResult {
    let outputAmountWithoutFees = this.getOutputAmount(tokenAmounts, inputTradeAmount, inputIndex, outputIndex);
    let fees = this.getFees(outputAmountWithoutFees);
    let expectedOutputAmount = JSBI.subtract(outputAmountWithoutFees, fees);

    return {
      priceImpact: this.getPriceImpact(tokenAmounts, inputTradeAmount, expectedOutputAmount, inputIndex, outputIndex),
      fees: fees,
      expectedOutputAmount: expectedOutputAmount,
    };
  }

  private getPriceImpact(
    tokenAmounts: JSBI[],
    inputTradeAmountJSBI: JSBI,
    expectedOutputAmountJSBI: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): Decimal {
    if (
      JSBI.equal(inputTradeAmountJSBI, ZERO) ||
      JSBI.equal(tokenAmounts[inputIndex], ZERO) ||
      JSBI.equal(tokenAmounts[outputIndex], ZERO)
    ) {
      return new Decimal(0);
    }

    const noSlippageOutputAmount = toDecimal(
      this.getOutputAmountWithNoSlippage(tokenAmounts, inputTradeAmountJSBI, inputIndex, outputIndex),
    );
    const expectedOutputAmount = toDecimal(expectedOutputAmountJSBI);
    const impact = noSlippageOutputAmount.sub(expectedOutputAmount).div(noSlippageOutputAmount);

    return impact;
  }

  private getFees(outputAmountWithoutFees: JSBI): JSBI {
    return JSBI.equal(this.traderFee.numerator, ZERO)
      ? ZERO
      : JSBI.divide(JSBI.multiply(outputAmountWithoutFees, this.traderFee.numerator), this.traderFee.denominator);
  }

  private getOutputAmount(tokenAmounts: JSBI[], inputTradeAmount: JSBI, inputIndex: number, outputIndex: number): JSBI {
    return this.curve.exchange(tokenAmounts, inputIndex, outputIndex, inputTradeAmount);
  }

  private getOutputAmountWithNoSlippage(
    tokenAmounts: JSBI[],
    inputTradeAmount: JSBI,
    inputIndex: number,
    outputIndex: number,
  ): JSBI {
    return this.curve.computeBaseY(tokenAmounts, inputIndex, outputIndex, inputTradeAmount);
  }
}
