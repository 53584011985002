import { Fraction, TokenSwapConstantProduct, TokenSwapStable } from '@jup-ag/math';
import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import { deserializeAccount } from '../../utils/deserializeAccount';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { createAldrinSwapInstruction, createAldrinV2SwapInstruction } from '../jupiterInstruction';
import { accountInfoToAldrinPoolState, AldrinPoolState, STABLE_CURVE_LAYOUT } from './poolState';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import Decimal from 'decimal.js';

interface AldrinParams {
  amp?: number;
}

export class AldrinAmm implements Amm {
  label = 'Aldrin' as const;
  poolState: AldrinPoolState;
  private tokenAccounts: TokenAccountInfo[] = [];
  calculator: TokenSwapConstantProduct | TokenSwapStable;

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>, private params: AldrinParams) {
    this.poolState = accountInfoToAldrinPoolState(address, accountInfo);

    if (this.poolState.curveType === 1) {
      const { amp } = this.params;
      if (!amp) {
        throw new Error('Amp is required for a stable curve');
      }

      this.calculator = new TokenSwapStable(
        JSBI.BigInt(amp),
        new Fraction(
          JSBI.BigInt(this.poolState.fees.traderFee.numerator.toString()),
          JSBI.BigInt(this.poolState.fees.traderFee.denominator.toString()),
        ),
        new Fraction(
          JSBI.BigInt(this.poolState.fees.ownerFee.numerator.toString()),
          JSBI.BigInt(this.poolState.fees.ownerFee.denominator.toString()),
        ),
      );
    } else {
      this.calculator = new TokenSwapConstantProduct(
        new Fraction(
          JSBI.BigInt(this.poolState.fees.traderFee.numerator.toString()),
          JSBI.BigInt(this.poolState.fees.traderFee.denominator.toString()),
        ),
        new Fraction(
          JSBI.BigInt(this.poolState.fees.ownerFee.numerator.toString()),
          JSBI.BigInt(this.poolState.fees.ownerFee.denominator.toString()),
        ),
      );
    }
  }

  static accountInfoToAldrinPoolState = accountInfoToAldrinPoolState;

  static decodeStableCurveAmp(accountInfo: AccountInfo<Buffer>) {
    const { amp } = STABLE_CURVE_LAYOUT.decode(accountInfo.data);

    return amp.toNumber() * 2; // times two for their AMP, dont ask me why, it is what it is
  }

  get id() {
    return this.poolState.address.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.poolState.quoteTokenVault, this.poolState.baseTokenVault];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) throw new Error('Invalid token account');
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    let feePct = new Decimal(this.poolState.fees.traderFee.numerator.toString())
      .div(this.poolState.fees.traderFee.denominator.toString())
      .add(
        new Decimal(this.poolState.fees.ownerFee.numerator.toString()).div(
          this.poolState.fees.ownerFee.denominator.toString(),
        ),
      );

    const outputIndex = this.tokenAccounts[0].mint.equals(sourceMint) ? 1 : 0;
    let result = this.calculator.exchange(tokenAccountsToJSBIs(this.tokenAccounts), JSBI.BigInt(amount), outputIndex);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.toNumber(result.expectedOutputAmount),
      feeAmount: JSBI.toNumber(result.fees),
      feeMint: sourceMint.toBase58(),
      feePct: feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams): TransactionInstruction[] {
    if (this.poolState.isV2) {
      if (!this.poolState.curve) {
        throw new Error('Unable to fetch curve account.');
      }

      return [
        createAldrinV2SwapInstruction(
          this.poolState,
          sourceMint,
          sourceTokenAccount,
          destinationTokenAccount,
          this.poolState.curve,
          userTransferAuthority,
          amount,
          minimumOutAmount,
          platformFee,
        ),
      ];
    }

    return [
      createAldrinSwapInstruction(
        this.poolState,
        sourceMint,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        amount,
        minimumOutAmount,
        platformFee,
      ),
    ];
  }

  get reserveTokenMints() {
    return [this.poolState.baseTokenMint, this.poolState.quoteTokenMint];
  }
}
