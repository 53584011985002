export function getTwoPermutations<T>(array: T[]) {
  return array.reduce((acc, item) => {
    array.forEach((otherItem) => {
      if (item !== otherItem) {
        acc.push([item, otherItem]);
      }
    });
    return acc;
  }, new Array<T[]>());
}
