import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, PublicKey, SystemProgram, TransactionInstruction } from '@solana/web3.js';
import { WRAPPED_SOL_MINT } from '../constants';
import { getEmptyInstruction, Instruction } from './instruction';

// Leverage the existing ATA when present
export async function createAndCloseWSOLAccount(
  connection: Connection,
  owner: PublicKey,
  amount: number,
): Promise<Instruction & { address: PublicKey }> {
  const result = getEmptyInstruction();
  result.instructions = [];

  const toAccount = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    WRAPPED_SOL_MINT,
    owner,
  );

  const info = await connection.getAccountInfo(toAccount);

  if (info === null) {
    result.instructions.push(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        WRAPPED_SOL_MINT,
        toAccount,
        owner,
        owner,
      ),
    );
  }

  // Fund account and sync
  result.instructions.push(
    SystemProgram.transfer({
      fromPubkey: owner,
      toPubkey: toAccount,
      lamports: amount,
    }),
  );
  result.instructions.push(
    // This is not exposed by the types, but indeed it exists
    (Token as any).createSyncNativeInstruction(TOKEN_PROGRAM_ID, toAccount),
  );

  result.cleanupInstructions = [Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, toAccount, owner, owner, [])];

  return {
    address: toAccount,
    ...result,
  };
}

export async function findOrCreateAssociatedAccountByMint(
  connection: Connection,
  payer: PublicKey,
  owner: PublicKey,
  mintAddress: PublicKey | string,
  unwrapSOL: boolean,
): Promise<Instruction & { address: PublicKey }> {
  const mint = typeof mintAddress === 'string' ? new PublicKey(mintAddress) : mintAddress;
  const toAccount = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, owner);
  const cleanupInstructions: TransactionInstruction[] = [];
  const instructions: TransactionInstruction[] = [];

  const info = await connection.getAccountInfo(toAccount);
  if (info === null) {
    instructions.push(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        toAccount,
        owner,
        payer,
      ),
    );
  }

  // We close it when wrapped SOL
  if (mint.equals(WRAPPED_SOL_MINT) && unwrapSOL) {
    cleanupInstructions.push(Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, toAccount, owner, owner, []));
  }

  return {
    address: toAccount,
    instructions: instructions,
    cleanupInstructions,
    signers: [],
  };
}
