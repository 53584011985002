import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { blob, struct, u8 } from '@solana/buffer-layout';
import { u64 } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import { publicKey, uint64 } from '../../utils/layout';

interface CropperTokenSwapLayout {
  version: number;
  isInitialized: number;
  nonce: number;
  ammId: PublicKey;
  serumProgramId: PublicKey;
  serumMarket: PublicKey;
  tokenProgramId: PublicKey;
  tokenAAccount: PublicKey;
  tokenBAccount: PublicKey;
  poolMint: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
}

export const CropperTokenSwapLayout = struct<CropperTokenSwapLayout>([
  u8('version'),
  u8('isInitialized'),
  u8('nonce'),
  publicKey('ammId'),
  publicKey('serumProgramId'),
  publicKey('serumMarket'),
  publicKey('tokenProgramId'),
  publicKey('tokenAAccount'),
  publicKey('tokenBAccount'),
  publicKey('poolMint'),
  publicKey('mintA'),
  publicKey('mintB'),
]);

interface CropperStateLayout {
  isInitialized: number;
  stateOwner: PublicKey;
  feeOwner: PublicKey;
  initialSupply: u64;
  returnFeeNumerator: u64;
  fixedFeeNumerator: u64;
  feeDenominator: u64;
  curveType: number;
  curveParameters: Uint8Array;
}

const CropperStateLayout = struct<CropperStateLayout>([
  u8('isInitialized'),
  publicKey('stateOwner'),
  publicKey('feeOwner'),
  uint64('initialSupply'),
  uint64('returnFeeNumerator'),
  uint64('fixedFeeNumerator'),
  uint64('feeDenominator'),
  u8('curveType'),
  blob(32, 'curveParameters'),
]);

export interface CropperState {
  isInitialized: boolean;
  stateOwner: PublicKey;
  feeOwner: PublicKey;
  initialSupply: u64;
  returnFeeNumerator: number;
  fixedFeeNumerator: number;
  feeDenominator: number;
  curveType: number;
  curveParameters: Uint8Array;
}

export interface CropperPoolState {
  programId: PublicKey;
  authority: PublicKey;
  version: number;
  isInitialized: boolean;
  nonce: number;
  ammId: PublicKey;
  serumProgramId: PublicKey;
  serumMarket: PublicKey;
  tokenProgramId: PublicKey;
  tokenAAccount: PublicKey;
  tokenBAccount: PublicKey;
  poolMint: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
}

// This seems to be hardcoded.
export const CROPPER_STATE_ADDRESS = new PublicKey('3hsU1VgsBgBgz5jWiqdw9RfGU6TpWdCmdah1oi4kF3Tq');

export const accountInfoToCropperPoolState = (
  address: PublicKey,
  accountInfo: AccountInfo<Buffer>,
): CropperPoolState => {
  const programId = accountInfo.owner;

  const decoded = CropperTokenSwapLayout.decode(accountInfo.data);
  const [authority] = findProgramAddressSync([address.toBuffer()], programId);

  return {
    programId,
    authority,
    version: decoded.version,
    isInitialized: Boolean(decoded.isInitialized),
    nonce: decoded.nonce,
    ammId: decoded.ammId,
    serumProgramId: decoded.serumProgramId,
    tokenProgramId: decoded.tokenProgramId,
    tokenAAccount: decoded.tokenAAccount,
    tokenBAccount: decoded.tokenBAccount,
    serumMarket: decoded.serumMarket,
    poolMint: decoded.poolMint,
    mintA: decoded.mintA,
    mintB: decoded.mintB,
  };
};

export const stateAccountInfoToCropperState = (accountInfo: AccountInfo<Buffer>): CropperState => {
  const decoded = CropperStateLayout.decode(accountInfo.data);

  return {
    isInitialized: Boolean(decoded.isInitialized),
    stateOwner: decoded.stateOwner,
    feeOwner: decoded.feeOwner,
    initialSupply: decoded.initialSupply,
    returnFeeNumerator: decoded.returnFeeNumerator.toNumber(),
    fixedFeeNumerator: decoded.fixedFeeNumerator.toNumber(),
    feeDenominator: decoded.feeDenominator.toNumber(),
    curveType: decoded.curveType,
    curveParameters: decoded.curveParameters,
  };
};
