import React, { useEffect, useState, useMemo, useCallback, useRef, createContext, useContext } from 'react';
import { Connection, PublicKey, TransactionResponse, Cluster, TransactionSignature } from '@solana/web3.js';
import { Errors } from './error';
import type { SignerWalletAdapter } from '@solana/wallet-adapter-base';
import useDebounce from './utils/useDebounce';
import {
  RouteInfo,
  Jupiter,
  SwapResult,
  TOKEN_LIST_URL,
  MARKETS_URL,
  TransactionFeeInfo,
  getRouteInfoUniqueId,
  PlatformFeeAndAccounts,
  QuoteMintToReferrer,
  IConfirmationTxDescription,
} from '@jup-ag/core';

export type JupiterError = typeof Errors[keyof typeof Errors];
export type { IConfirmationTxDescription };

interface UseJupiterResult {
  /** routes that are possible, sorted decending based on outAmount */
  routes?: RouteInfo[];
  /** exchange function to submit transaction */
  exchange: (params: {
    wallet: Pick<SignerWalletAdapter, 'signAllTransactions' | 'publicKey' | 'sendTransaction' | 'signTransaction'>;
    route: RouteInfo;
    /** a confirmation waiter factory to make a confirmation waiter for each transaction */
    confirmationWaiterFactory: (
      txid: TransactionSignature,
      totalTxs: number,
      txDescription: IConfirmationTxDescription,
    ) => Promise<TransactionResponse | null>;
  }) => Promise<SwapResult>;
  /** refresh function to refetch the prices */
  refresh: () => void;
  /** last refresh timestamp */
  lastRefreshTimestamp: number;
  /** all possible token mints to be chosen from */
  allTokenMints: string[];
  /** route map input mint with output mints */
  routeMap: Map<string, string[]>;
  /** loading state */
  loading: boolean;
  error: JupiterError | undefined;
}

interface JupiterProps {
  connection: Connection;
  cluster: Cluster;
  userPublicKey?: PublicKey;
  platformFeeAndAccounts?: PlatformFeeAndAccounts;
  quoteMintToReferrer?: QuoteMintToReferrer;
  routeCacheDuration?: number;
  /* custom jupiter market url */
  marketUrl?: string;
}

const JupiterContext = createContext<
  | (Pick<UseJupiterResult, 'allTokenMints' | 'routeMap'> & {
      connection: Connection;
      cluster: string;
      jupiter: Jupiter | undefined;
      error: JupiterError | undefined;
      setError: (error?: JupiterError) => void;
    })
  | null
>(null);

export const JupiterProvider: React.FC<JupiterProps> = ({
  connection,
  cluster,
  userPublicKey,
  children,
  platformFeeAndAccounts,
  quoteMintToReferrer,
  routeCacheDuration,
  marketUrl,
}) => {
  const [jupiter, setJupiter] = useState<Jupiter>();

  const [error, setError] = useState<JupiterError>();

  useEffect(() => {
    (async () => {
      try {
        const _jupiter = await Jupiter.load({
          connection,
          cluster,
          user: userPublicKey,
          platformFeeAndAccounts,
          routeCacheDuration,
          quoteMintToReferrer,
          marketUrl,
        });

        setJupiter(_jupiter);
      } catch (e) {
        console.error(e);
        setError(Errors.INITIALIZE_ERROR);
        throw e;
      }
    })();
  }, [connection, cluster]);

  useEffect(() => {
    if (jupiter && userPublicKey) {
      jupiter.setUserPublicKey(userPublicKey);
    }
  }, [jupiter, userPublicKey]);

  const routeMap = useMemo(() => {
    let routeMap = new Map<string, string[]>();

    if (jupiter) {
      routeMap = jupiter.getRouteMap();
    }
    return routeMap;
  }, [jupiter]);

  const allTokenMints = useMemo(() => {
    return Array.from(routeMap.keys());
  }, [routeMap]);

  return (
    <JupiterContext.Provider
      value={{
        jupiter,
        allTokenMints,
        connection,
        cluster,
        routeMap,
        error,
        setError,
      }}
    >
      {children}
    </JupiterContext.Provider>
  );
};

interface UseJupiterProps {
  amount: number;
  inputMint: PublicKey | undefined;
  outputMint: PublicKey | undefined;
  slippage: number;
  /* inputAmount is being debounced, debounceTime 0 to disable debounce */
  debounceTime?: number;
}

export const useJupiterRouteMap = () => {
  const context = useContext(JupiterContext);
  if (!context) {
    throw new Error('JupiterProvider is required');
  }
  return context.routeMap;
};

export const useJupiter = ({
  amount,
  inputMint,
  outputMint,
  slippage,
  debounceTime = 250,
}: UseJupiterProps): UseJupiterResult => {
  const context = useContext(JupiterContext);
  const [loading, setLoading] = useState(true);
  const [routes, setRoutes] = useState<RouteInfo[]>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  // lastRefreshCount indicate when the last refresh was triggered on which refreshCount
  const lastRefreshCount = useRef<number>(refreshCount);

  const debouncedAmount = useDebounce(amount, debounceTime);

  const lastRefreshTimestamp = useRef<number>(new Date().getTime());
  const lastQueryTimestamp = useRef<number>(new Date().getTime());

  if (!context) {
    throw new Error('JupiterProvider is required');
  }

  const { routeMap, allTokenMints, jupiter, error, setError } = context;

  // lastRefreshCount to determine when the last refresh was triggered, reset this to -1 to trigger a re-fetch
  useEffect(() => {
    lastRefreshCount.current = -1;
  }, [[inputMint?.toString(), outputMint?.toString()].sort().join('-')]);

  useEffect(() => {
    // don't set loading if there is no input amount
    if (amount && debouncedAmount && refreshCount !== lastRefreshCount.current) {
      setLoading(true);
    }
  }, [refreshCount, lastRefreshCount.current, debouncedAmount, slippage]);

  useEffect(() => {
    if (!jupiter) {
      return;
    }

    if (!debouncedAmount || error === Errors.INITIALIZE_ERROR) {
      setRoutes(undefined);
      setLoading(false);
    } else if (debouncedAmount) {
      if (!inputMint || !outputMint || !routeMap) return;
      let lastUpdatedTime = new Date().getTime();
      lastQueryTimestamp.current = lastUpdatedTime;

      jupiter
        .computeRoutes({ inputMint, outputMint, inputAmount: debouncedAmount, slippage, forceFetch: loading })
        .then(({ routesInfos, cached }) => {
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          setRoutes(routesInfos);
          setError(undefined);

          if (!cached) {
            lastRefreshTimestamp.current = new Date().getTime();
          }
        })
        .catch((e) => {
          console.error(e);
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          // Clear routes when erring to avoid bad pricing
          setRoutes(undefined);
          setError(Errors.ROUTES_ERROR);
        })
        .finally(() => {
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          lastRefreshCount.current = refreshCount;
          setLoading(false);
        });
    }
  }, [loading, jupiter, debouncedAmount, inputMint, outputMint, slippage]);

  const exchange: UseJupiterResult['exchange'] = useCallback(
    async ({ wallet, route, confirmationWaiterFactory }): Promise<SwapResult> => {
      if (error) {
        throw new Error(error);
      }

      if (!jupiter) {
        throw new Error('Jupiter not initialized');
      }

      if (!wallet?.publicKey) {
        throw new Error('Wallet not connected');
      }

      if (!route) {
        throw new Error('Invalid state, impossible to build transaction');
      }

      const { execute } = await jupiter.exchange({ route });

      const result = await execute({ wallet, confirmationWaiterFactory });

      return result;
    },
    [jupiter],
  );

  return {
    allTokenMints,
    routeMap,
    exchange,
    refresh: () => {
      if (!loading) {
        setRefreshCount((refreshCount) => refreshCount + 1);
      }
    },
    lastRefreshTimestamp: lastRefreshTimestamp.current,
    loading,
    routes,
    error,
  };
};

export { RouteInfo, getRouteInfoUniqueId, TOKEN_LIST_URL, MARKETS_URL, Errors, TransactionFeeInfo };
