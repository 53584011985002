import { Market, Orderbook } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';

const TAKER_FEE_PCT = 0.0004;
const STABLE_TAKER_FEE_PCT = 0.0001;

// Stable markets are hardcoded in the program
const STABLE_MARKET_ADDRESSES = [
  '77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS', // USDT/USDC
  '5cLrMai1DsLRYc1Nio9qMTicsWtvzjzZfJPXyAoF4t1Z', // mSOL/SOL
  'EERNEEnBqdGzBS8dd46wwNY5F2kwnaCQ3vsq2fNKGogZ', // UST/USDC
  '8sFf9TW3KzxLiBXcDcjAxqabEsRroo4EiRr3UG1xbJ9m', // UST/USDT
  '2iDSTGhjJEiRxNaLF27CY6daMYPs5hgYrP2REHd5YD62', // stSOL/SOL
];

interface IMarketMeta {
  /** buy or sell side */
  side: 'buy' | 'sell';
  /** indicate that your order is too huge for the market */
  notEnoughLiquidity: boolean;
  /** minimum in amount and the corresponding out amount */
  minimum: {
    in: number;
    out: number;
  };
  /** amount in taken for the trade */
  inAmount: number;
  /** the amount out for the trade */
  outAmount: number;
  /** the total fee amount */
  feeAmount: number;
  /** price impact percentage */
  priceImpactPct: number;
  /** fee percentage */
  feePct: number;
}

// Provides swap like out amount, with slippage and corresponding minimum amount out
export function getOutAmountMeta({
  market,
  asks,
  bids,
  fromAmount,
  fromMint,
  toMint,
}: {
  market: Market;
  asks: Orderbook;
  bids: Orderbook;
  fromMint: PublicKey;
  toMint: PublicKey;
  fromAmount: number;
}) {
  const takerFeePct = STABLE_MARKET_ADDRESSES.includes(market.address.toBase58())
    ? STABLE_TAKER_FEE_PCT
    : TAKER_FEE_PCT;

  if (fromMint.equals(market.quoteMintAddress) && toMint.equals(market.baseMintAddress)) {
    // buy
    return forecastBuy(market, asks, fromAmount, takerFeePct);
  } else {
    return forecastSell(market, bids, fromAmount, takerFeePct);
  }
}

export function forecastBuy(market: Market, orderBook: Orderbook, pcIn: number, takerFeePct: number): IMarketMeta {
  let coinOut = 0;
  let bestPrice = 0;
  let worstPrice = 0;
  // total base price
  let totalCost = 0;
  let totalCoins = 0;

  // Serum buy order take fee in quote tokens
  let availablePc = pcIn / (1 + takerFeePct);

  const baseSizeLots = market.baseSizeLotsToNumber(new BN(1));
  const quoteSizeLots = market.quoteSizeLotsToNumber(new BN(1));

  for (const order of orderBook.items(false)) {
    const price = market.priceLotsToNumber(order.priceLots);
    const size = market.baseSizeLotsToNumber(order.sizeLots);
    totalCoins += size;

    if (!bestPrice && price !== 0) {
      bestPrice = price;
    }
    worstPrice = price;

    const orderCoinAmount = order.sizeLots.toNumber() * baseSizeLots;
    const orderPcAmount = order.sizeLots.toNumber() * order.priceLots.toNumber() * quoteSizeLots;

    const lotPrice = order.priceLots.toNumber() * quoteSizeLots;

    if (orderPcAmount >= availablePc) {
      const numberLotsCanBuy = Math.floor(availablePc / lotPrice);
      totalCost += numberLotsCanBuy * lotPrice;
      coinOut += numberLotsCanBuy * baseSizeLots;
      availablePc -= numberLotsCanBuy * lotPrice;
      break;
    } else {
      totalCost += order.sizeLots.toNumber() * lotPrice;
      coinOut += orderCoinAmount;
      availablePc -= orderPcAmount;
    }
  }

  const priceImpactPct = bestPrice ? (worstPrice - bestPrice) / bestPrice : 0;

  return {
    side: 'buy',
    notEnoughLiquidity: totalCoins <= coinOut,
    minimum: {
      in: Math.ceil(baseSizeLots * bestPrice * (1 + takerFeePct)),
      out: baseSizeLots,
    },
    inAmount: Math.ceil(totalCost * (1 + takerFeePct)),
    outAmount: coinOut,
    feeAmount: Math.round(totalCost * takerFeePct),
    priceImpactPct,
    feePct: takerFeePct,
  };
}

export function forecastSell(market: Market, orderBook: Orderbook, coinIn: number, takerFeePct: number): IMarketMeta {
  let pcOut = 0;
  let bestPrice = 0;
  let worstPrice = 0;
  let availableCoin = coinIn;
  let inAmount = 0;

  const baseSizeLots = market.baseSizeLotsToNumber(new BN(1));
  const quoteSizeLots = market.quoteSizeLotsToNumber(new BN(1));

  for (const order of orderBook.items(true)) {
    const price = market.priceLotsToNumber(order.priceLots);

    if (!bestPrice && price !== 0) {
      bestPrice = price;
    }

    worstPrice = price;

    const orderCoinAmount = order.sizeLots.toNumber() * baseSizeLots;
    const orderPcAmount = order.sizeLots.toNumber() * order.priceLots.toNumber() * quoteSizeLots;

    if (availableCoin <= orderCoinAmount) {
      const numberLotsCanSell = Math.floor(availableCoin / baseSizeLots);
      pcOut += numberLotsCanSell * order.priceLots.toNumber() * quoteSizeLots;
      availableCoin = 0;
      inAmount += numberLotsCanSell * baseSizeLots;
      break;
    } else {
      pcOut += orderPcAmount;
      availableCoin -= orderCoinAmount;
      inAmount += orderCoinAmount;
    }
  }

  pcOut = Math.floor(pcOut * (1 - takerFeePct));

  const priceImpactPct = bestPrice ? (bestPrice - worstPrice) / bestPrice : 0;

  return {
    side: 'sell',
    notEnoughLiquidity: availableCoin > 0,
    minimum: {
      in: baseSizeLots,
      out: Math.ceil(baseSizeLots * bestPrice * (1 - takerFeePct)),
    },
    inAmount: inAmount,
    outAmount: pcOut,
    feeAmount: Math.round(pcOut * takerFeePct),
    priceImpactPct,
    feePct: takerFeePct,
  };
}
