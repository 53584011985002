import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { chunkedGetMultipleAccountInfos } from '../utils/chunkedGetMultipleAccountInfos';
import { MarketInfo } from './market';
import { isPlatformFeeSupported, Route, RouteInfo, TransactionFeeInfo } from './routes';

const PLATFORM_FEE_DENOMINATOR = 10000;

export async function fetchRoutes(connection: Connection, routes: Route[]): Promise<Route[]> {
  await routeBatchFetcher(connection, routes);

  return routes;
}

interface GetQuotesParams {
  routes: Route[];
  amount: number;
  inputMint: PublicKey;
  outputMint: PublicKey;
  platformFeeBps: number;
  slippage: number;
  getDepositAndFeeForRoute: (marketInfos: RouteInfo['marketInfos']) => Promise<TransactionFeeInfo | undefined>;
}

export const computeRouteInfos = ({
  routes,
  amount,
  inputMint,
  outputMint,
  platformFeeBps,
  slippage,
  getDepositAndFeeForRoute,
}: GetQuotesParams) => {
  const routesInfo: RouteInfo[] = routes
    .map((route) => {
      const { marketMetas, intermediateMint } = route;

      // Chain all marketMetas
      let marketInfos: MarketInfo[] = [];
      let intermediateAmount = amount;
      let outAmountWithSlippage = amount;
      const platformFeeSupported = isPlatformFeeSupported(marketMetas);
      const tokenMints: PublicKey[] = [inputMint, outputMint];
      // TODO: Avoid this hack with a smarter data structure
      if (intermediateMint) {
        tokenMints.splice(1, 0, intermediateMint);
      }

      const legs = marketMetas.length;
      for (const [i, marketMeta] of marketMetas.entries()) {
        try {
          const sourceMint = tokenMints[i];
          const destinationMint = tokenMints[i + 1];
          const quote = marketMeta.amm.getQuote({
            sourceMint,
            destinationMint,
            amount: intermediateAmount,
          });

          // Platform fee applicable only on last leg
          const platformFee =
            legs - 1 === i && platformFeeSupported
              ? {
                  amount: Math.floor((quote.outAmount * platformFeeBps) / PLATFORM_FEE_DENOMINATOR),
                  mint: destinationMint.toBase58(),
                  pct: platformFeeBps / 100,
                }
              : { amount: 0, mint: destinationMint.toBase58(), pct: 0 };

          const outAmountAfterFees = Math.max(0, quote.outAmount - platformFee.amount);

          const legOutAmountWithSlippage = Math.round(outAmountAfterFees * (1 - slippage / 100));

          marketInfos.push({
            marketMeta,
            inputMint: sourceMint,
            outputMint: destinationMint,
            notEnoughLiquidity: quote.notEnoughLiquidity,
            minInAmount: quote.minInAmount,
            minOutAmount: quote.minOutAmount,
            inAmount: quote.inAmount,
            outAmount: outAmountAfterFees,
            priceImpactPct: quote.priceImpactPct,
            lpFee: {
              amount: quote.feeAmount,
              mint: quote.feeMint,
              pct: quote.feePct,
            },
            platformFee,
          });

          intermediateAmount = outAmountAfterFees;
          outAmountWithSlippage = legOutAmountWithSlippage;
        } catch (e: any) {
          // we supress this error because it is not too critical and it's serum specific
          if (e.message === 'Number can only safely store up to 53 bits') {
            return undefined;
          }
          throw e;
        }
      }

      return {
        marketInfos,
        getDepositAndFee: () => getDepositAndFeeForRoute(marketInfos),
        inAmount: marketInfos[0].inAmount,
        outAmount: intermediateAmount,
        outAmountWithSlippage: outAmountWithSlippage,
        priceImpactPct:
          1 -
          marketInfos.reduce((priceFactor, marketInfo) => {
            priceFactor *= 1 - marketInfo.priceImpactPct;
            return priceFactor;
          }, 1),
      };
    })
    .filter((item): item is RouteInfo => item !== undefined)
    .sort((a, b) => b.outAmount - a.outAmount); // sort based on which one have better output

  return routesInfo;
};

async function routeBatchFetcher(connection: Connection, routes: Route[]) {
  const accountInfosMap = new Map();

  const accountsToFetchSet = new Set<string>();

  routes.forEach(({ marketMetas }) => {
    return marketMetas.forEach(({ amm }) => {
      amm.getAccountsForUpdate().forEach((account) => {
        // Only add accountInfos that is not in the Map
        accountsToFetchSet.add(account.toBase58());
      });
    });
  });

  const accountsToFetch = Array.from(accountsToFetchSet);

  if (accountsToFetch.length > 0) {
    const accountInfos = await chunkedGetMultipleAccountInfos(
      connection,
      accountsToFetch.map((account) => new PublicKey(account)),
    );

    accountInfos.forEach((item, index) => {
      const publicKey = accountsToFetch[index];
      if (item) {
        accountInfosMap.set(publicKey, item);
      }
    });
  }

  routes.forEach(({ marketMetas }) => {
    marketMetas.forEach(({ amm }) => {
      amm.update(accountInfosMap);
    });
  });
}
