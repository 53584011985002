import { AccountInfo, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  SwapParams,
  tokenAccountsToJSBIs,
} from '../amm';
import { createTokenSwapInstruction } from '../jupiterInstruction';
import { accountInfoToTokenSwapState, TokenSwapState } from './tokenSwapLayout';
import { deserializeAccount } from '../../utils/deserializeAccount';
import { Fraction, TokenSwapConstantProduct, TokenSwapStable } from '@jup-ag/math';
import JSBI from 'jsbi';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import { STEP_TOKEN_SWAP_PROGRAM_ID } from './splTokenSwapPools';

enum CurveType {
  ConstantProduct = 0,
  Stable = 2,
}

// Abstract any SPL token swap based AMM
export class SplTokenSwapAmm implements Amm {
  private tokenSwapState: TokenSwapState;
  private curveType: CurveType;

  private tokenAccounts: TokenAccountInfo[] = [];
  calculator: TokenSwapConstantProduct | TokenSwapStable;

  constructor(address: PublicKey, swapStateAccountInfo: AccountInfo<Buffer>, public label: string) {
    this.tokenSwapState = accountInfoToTokenSwapState(address, swapStateAccountInfo);

    this.curveType = this.tokenSwapState.curveType;

    if (!(this.curveType in CurveType)) {
      throw new Error(`curveType ${this.tokenSwapState.curveType} is not supported`);
    }

    if (this.tokenSwapState.curveType === CurveType.ConstantProduct) {
      this.calculator = new TokenSwapConstantProduct(
        new Fraction(
          JSBI.BigInt(this.tokenSwapState.tradeFeeNumerator.toString()),
          JSBI.BigInt(this.tokenSwapState.tradeFeeDenominator.toString()),
        ),
        new Fraction(
          JSBI.BigInt(this.tokenSwapState.ownerTradeFeeNumerator.toString()),
          JSBI.BigInt(this.tokenSwapState.ownerTradeFeeDenominator.toString()),
        ),
      );
    } else {
      this.calculator = new TokenSwapStable(
        JSBI.BigInt(this.tokenSwapState.curveParameters[0]),
        new Fraction(
          JSBI.BigInt(this.tokenSwapState.tradeFeeNumerator.toString()),
          JSBI.BigInt(this.tokenSwapState.tradeFeeDenominator.toString()),
        ),
        new Fraction(
          JSBI.BigInt(this.tokenSwapState.ownerTradeFeeNumerator.toString()),
          JSBI.BigInt(this.tokenSwapState.ownerTradeFeeDenominator.toString()),
        ),
      );
    }
  }

  get id() {
    return this.tokenSwapState.address.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.tokenSwapState.tokenAccountA, this.tokenSwapState.tokenAccountB];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) {
        throw new Error('Invalid token account');
      }
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    let feePct = new Decimal(this.tokenSwapState.tradeFeeNumerator.toString())
      .div(this.tokenSwapState.tradeFeeDenominator.toString())
      .add(
        new Decimal(this.tokenSwapState.ownerTradeFeeNumerator.toString()).div(
          this.tokenSwapState.ownerTradeFeeDenominator.toString(),
        ),
      );

    const outputIndex = this.tokenAccounts[0].mint.equals(sourceMint) ? 1 : 0;
    let result = this.calculator.exchange(tokenAccountsToJSBIs(this.tokenAccounts), JSBI.BigInt(amount), outputIndex);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.toNumber(result.expectedOutputAmount),
      feeAmount: JSBI.toNumber(result.fees),
      feeMint: sourceMint.toBase58(),
      feePct: feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams) {
    return [
      createTokenSwapInstruction(
        this.tokenSwapState,
        sourceMint,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        amount,
        minimumOutAmount,
        platformFee,
        this.tokenSwapState.programId.equals(STEP_TOKEN_SWAP_PROGRAM_ID),
      ),
    ];
  }

  get reserveTokenMints() {
    return [this.tokenSwapState.mintA, this.tokenSwapState.mintB];
  }
}
