import { Fraction, TokenSwapConstantProduct } from '@jup-ag/math';
import { AccountInfo, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import { deserializeAccount } from '../../utils/deserializeAccount';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { createCropperSwapInstruction } from '../jupiterInstruction';

import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import {
  accountInfoToCropperPoolState,
  CropperPoolState,
  stateAccountInfoToCropperState,
  CROPPER_STATE_ADDRESS,
} from './swapLayout';
import Decimal from 'decimal.js';

interface CropperParams {
  tokenAFeeAccount: PublicKey;
  tokenBFeeAccount: PublicKey;
  returnFeeNumerator: number;
  fixedFeeNumerator: number;
  feeDenominator: number;
}

export class CropperAmm implements Amm {
  label = 'Cropper' as const;
  poolState: CropperPoolState;
  private tokenAccounts: TokenAccountInfo[] = [];
  private calculator: TokenSwapConstantProduct;
  private feePct: Decimal;

  // Hardcoded because no where to query this
  static async getStateFromStateAccount(connection: Connection) {
    const accountInfo = await connection.getAccountInfo(CROPPER_STATE_ADDRESS);

    if (!accountInfo) {
      throw new Error('State account not found');
    }

    return stateAccountInfoToCropperState(accountInfo);
  }

  static decodePoolState = accountInfoToCropperPoolState;

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>, private params: CropperParams) {
    this.poolState = accountInfoToCropperPoolState(address, accountInfo);

    this.feePct = new Decimal(this.params.fixedFeeNumerator)
      .div(this.params.feeDenominator)
      .add(new Decimal(this.params.returnFeeNumerator).div(this.params.feeDenominator));

    this.params.tokenAFeeAccount = new PublicKey(this.params.tokenAFeeAccount);
    this.params.tokenBFeeAccount = new PublicKey(this.params.tokenBFeeAccount);

    this.calculator = new TokenSwapConstantProduct(
      new Fraction(JSBI.BigInt(this.params.fixedFeeNumerator), JSBI.BigInt(this.params.feeDenominator)),
      new Fraction(JSBI.BigInt(this.params.returnFeeNumerator), JSBI.BigInt(this.params.feeDenominator)),
    );
  }

  get id() {
    return this.poolState.ammId.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.poolState.tokenAAccount, this.poolState.tokenBAccount];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) {
        throw new Error('Invalid token account');
      }
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    const outputIndex = this.tokenAccounts[0].mint.equals(sourceMint) ? 1 : 0;
    const result = this.calculator.exchange(tokenAccountsToJSBIs(this.tokenAccounts), JSBI.BigInt(amount), outputIndex);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.toNumber(result.expectedOutputAmount),
      feeAmount: JSBI.toNumber(result.fees),
      feeMint: sourceMint.toBase58(),
      feePct: this.feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions({
    sourceMint,
    sourceTokenAccount,
    destinationTokenAccount,
    userTransferAuthority,
    amount,
    minimumOutAmount,
    platformFee,
  }: SwapParams): TransactionInstruction[] {
    const feeAccount = sourceMint.equals(this.poolState.mintA)
      ? this.params.tokenAFeeAccount
      : this.params.tokenBFeeAccount;

    return [
      createCropperSwapInstruction(
        this.poolState,
        sourceMint,
        sourceTokenAccount,
        destinationTokenAccount,
        userTransferAuthority,
        feeAccount,
        amount,
        minimumOutAmount,
        platformFee,
      ),
    ];
  }

  get reserveTokenMints() {
    return [this.poolState.mintA, this.poolState.mintB];
  }
}
