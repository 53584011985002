import { InstructionCache } from './types';
import { RouteInfo } from './routes';
import { SetupInstructions } from '..';

export const getCacheMintKey = (marketInfos: RouteInfo['marketInfos']) => {
  return `${marketInfos[0]?.inputMint}-${marketInfos[0]?.outputMint}-${marketInfos[1]?.outputMint}-${marketInfos.map(
    (item) => item.marketMeta.amm.id,
  )}`;
};

export const getInstructionCache = ({
  instructionCache,
  walletPublicKey,
  cacheKey,
}: {
  instructionCache: InstructionCache;
  walletPublicKey: string;
  cacheKey: string;
}) => {
  return instructionCache.get(walletPublicKey)?.get(cacheKey);
};

export const setInstructionCache = ({
  instructionCache,
  instructionResult,
  walletPublicKey,
  cacheKey,
}: {
  instructionCache: InstructionCache;
  instructionResult: SetupInstructions;
  walletPublicKey: string;
  cacheKey: string;
}) => {
  const walletCache =
    instructionCache.get(walletPublicKey) || (new Map() as NonNullable<ReturnType<InstructionCache['get']>>);

  walletCache.set(cacheKey, instructionResult);

  instructionCache.set(walletPublicKey, walletCache);
};

export const deleteInstructionCache = ({
  instructionCache,
  walletPublicKey,
}: {
  instructionCache: InstructionCache;
  walletPublicKey: string;
}) => {
  instructionCache.get(walletPublicKey)?.clear();
};
