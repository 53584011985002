import { Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { isPlatformFeeSupported, RouteInfo } from './routes';
import { getEmptyInstruction, Instruction } from '../utils/instruction';
import { findOrCreateAssociatedAccountByMint } from '../utils/token';
import { createSetTokenLedgerInstruction } from './jupiterInstruction';
import { Owner } from '../utils/Owner';
import { PlatformFee } from './types';
import { QuoteMintToReferrer } from '..';

async function routeToInstructions(
  user: Owner,
  openOrdersAddresses: (PublicKey | undefined)[],
  userSourceTokenAccountAddress: PublicKey,
  userIntermediaryTokenAccountAddress: PublicKey | undefined,
  userDestinationTokenAccountAddress: PublicKey,
  routeInfo: RouteInfo,
  platformFee: PlatformFee | undefined,
  quoteMintToReferrer: QuoteMintToReferrer,
): Promise<Instruction> {
  const outAmountWithSlippage = routeInfo.outAmountWithSlippage;
  const inputAmount = routeInfo.inAmount;

  const legs = routeInfo.marketInfos.length;
  if (legs == 2 && !userIntermediaryTokenAccountAddress) {
    throw new Error('Missing intermediary token account');
  }

  const userTokenAccountAddresses =
    legs === 1
      ? [userSourceTokenAccountAddress, userDestinationTokenAccountAddress]
      : [userSourceTokenAccountAddress, userIntermediaryTokenAccountAddress!, userDestinationTokenAccountAddress];

  let instructions: TransactionInstruction[] = [];

  const platformFeeSupported = isPlatformFeeSupported(routeInfo.marketInfos.map((mi) => mi.marketMeta));

  if (legs > 1) {
    instructions.push(createSetTokenLedgerInstruction(userIntermediaryTokenAccountAddress!));
  }

  for (const [index, marketInfo] of routeInfo.marketInfos.entries()) {
    const amm = marketInfo.marketMeta.amm;
    const legInputAmount = index === 0 ? inputAmount : null;
    const legOutAmountWithSlippage = index === legs - 1 ? outAmountWithSlippage : 0;
    const legPlatformFee = index === legs - 1 && platformFeeSupported ? platformFee : undefined;

    const [source, destination] = userTokenAccountAddresses.slice(index);

    instructions.push(
      ...amm.createSwapInstructions({
        sourceMint: marketInfo.inputMint,
        destinationMint: marketInfo.outputMint,
        sourceTokenAccount: source,
        destinationTokenAccount: destination,
        userTransferAuthority: user.publicKey,
        amount: legInputAmount,
        minimumOutAmount: legOutAmountWithSlippage,
        openOrdersAddress: openOrdersAddresses[index],
        platformFee: legPlatformFee,
        quoteMintToReferrer,
      }),
    );
  }

  const { signers, cleanupInstructions } = getEmptyInstruction();

  if (user.isKeyPair && user.signer) {
    signers.push(user.signer);
  }
  return {
    signers,
    cleanupInstructions,
    instructions,
  };
}

export const routeAtaInstructions = async (
  connection: Connection,
  marketInfos: RouteInfo['marketInfos'],
  userPublicKey: PublicKey,
  unwrapSOL: boolean,
) => {
  const getUserIntermediateTokenAccountAddress = async () => {
    const userIntermediateTokenAccountAddress =
      marketInfos.length === 2
        ? await findOrCreateAssociatedAccountByMint(
            connection,
            userPublicKey,
            userPublicKey,
            marketInfos[0].outputMint,
            unwrapSOL,
          )
        : undefined;
    return userIntermediateTokenAccountAddress;
  };

  const getUserDestinationTokenAccountAddress = async () => {
    return await findOrCreateAssociatedAccountByMint(
      connection,
      userPublicKey,
      userPublicKey,
      marketInfos.length === 2 ? marketInfos[1].outputMint : marketInfos[0].outputMint,
      unwrapSOL,
    );
  };

  const [userIntermediaryTokenAccountResult, userDestinationTokenAccountResult] = await Promise.all([
    getUserIntermediateTokenAccountAddress(),
    getUserDestinationTokenAccountAddress(),
  ]);

  return {
    userIntermediaryTokenAccountResult,
    userDestinationTokenAccountResult,
  };
};

export default routeToInstructions;
